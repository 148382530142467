<template>
  <component :is="tag" :class="['aspectratio', `ratio-${ratio}`]">
    <span>
      <slot/>
    </span>
  </component>
</template>

<script>
export default {
  name: 'AspectRatio',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    ratio: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.aspectratio {
  display: block;
  position: relative;
  width: 100%;

  &:before {
    display: block;
    content: '';
  }

  &.ratio-3x4:before {
    padding-top:133%
  }

  &.ratio-1x1:before {
    padding-top:100%
  }

  &.ratio-4x3:before {
    padding-top:75%
  }

  &.ratio-2x1:before {
    padding-top:50%
  }

  > span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
