<template>
  <div>
    <!-- if this is active route (e.g. /orders) -->
    <template v-if="isCurrentRoute">
      <entity-entry-header :entityKey="eCurrent.key" :title="$t(`views.${eCurrent.key}.title`)"/>

      <entity-entry-table :entityKey="eCurrent.key" :tableSettings="table" searchable>
        <template #cell(orderstatelabel)="data">
          <order-state-badge :statename="data.cell.item.orderstate" :label="data.cell.item.orderstatelabel" />
        </template>

        <template #cell(requiredactions)="data">
          <div class="badge-group">
            <b-badge v-for="action in data.cell.item.requiredactions" :key="action" variant="warning">{{ action }}</b-badge>
          </div>
        </template>

        <template #cell(orderdate)="data">
          {{ formatDate(data.cell.item.orderdate) }}
        </template>
      </entity-entry-table>
    </template>

    <!-- if subroute is active route (e.g. /orders/detail) -->
    <section v-else>
      <entity-detail-header :entityKey="eCurrent.key" :entityId="eCurrent.id">
        <template #default="data">
         <b-row align-v="end">
         <b-col lg>
          <dl class="detailheader-list">
            <dt class="sr-only">{{ data.definition.$properties.orderId.translations[$i18n.locale].orderId }}</dt>
            <dd><h1>{{ data.entity.orderId }}</h1></dd>

            <div class="w-100"></div>
            <dt>Standort:</dt>
            <dd v-if="data.entity.configuration">{{ data.entity.configuration.location.name }} <b-badge v-if="data.entity.configuration.ownLocation ? data.entity.configuration.location.id !== data.entity.configuration.ownLocation.id :false" variant="primary">Fremdbestellung</b-badge></dd>

            <div class="w-100"></div>
            <dt>DiasId:</dt>
            <dd v-if="data.entity.configuration">{{ data.entity.configuration.location.customerId }}</dd>

            <div class="w-100"></div>
            <dt>Benutzer:</dt>
            <dd v-if="data.entity.configuration">{{ data.entity.configuration.user.firstname }} {{ data.entity.configuration.user.lastname }} ({{ data.entity.configuration.user.email }})</dd>

            <div v-if="data.entity.executedBasket.configuration.orderReference" class="w-100"></div>
            <dt v-if="data.entity.executedBasket.configuration.orderReference">Referenz</dt>
            <dd v-if="data.entity.executedBasket.configuration.orderReference">{{data.entity.executedBasket.configuration.orderReference}}</dd>

            <div v-if="data.entity.executedBasket.configuration.orderComments" class="w-100"></div>
            <dt v-if="data.entity.executedBasket.configuration.orderComments">Hinweise zur Bestellung</dt>
            <dd v-if="data.entity.executedBasket.configuration.orderComments" class="text-danger font-weight-bold h4">{{data.entity.executedBasket.configuration.orderComments}}</dd>
          </dl>
        </b-col>
        <b-col lg class="text-right">
         <dd><strong>Status: </strong><order-state-badge  :statename="data.entity.orderState" :label="data.entity.orderStateLabel" /></dd>
         <div class="w-100"></div>
         <br />
         <br />
      </b-col>
      </b-row>
        </template>
      </entity-detail-header>

      <router-view/>
    </section>
  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_UNKNOWN } from '@/assets/js/config/client'

import { TableHelper } from '@/assets/js/helper/table'
import { valueFormatter } from '@/assets/js/helper/entity'

import MixinEntityBase from '@/components/mixins/EntityBase'
import EntityEntryHeader from '@/components/entity/EntryHeader'
import EntityEntryTable from '@/components/entity/EntryTable'
import EntityDetailHeader from '@/components/entity/DetailHeader'
import OrderStateBadge from '../../components/order/OrderStateBadge.vue'

import moment from 'moment'

const ENTITY_KEY = 'orders'

export default {
  name: 'Orders',
  mixins: [MixinEntityBase],
  components: {
    EntityEntryHeader,
    EntityEntryTable,
    EntityDetailHeader,
    OrderStateBadge
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      table: {
        helper: TableHelper,
        mapper: this.tableEntitiesMapper,
        options: {
          fields: {
            includes: [],
            excludes: [],
            sorting: [CLASS_TABLE_FIELD_KEY_UNKNOWN, 'requiredactions'],
            labelKey: `views.${this.$options.name.toLowerCase()}.table.label.{field}`,
            class: {
              requiredactions: 'cell-align-right'
            },
            thClass: {
              requiredactions: 'cell-min cell-nowrap'
            }
          }
        },
        events: {
          rowClicked: this.tableRowClicked
        }
      }
    }
  },
  computed: {
    isCurrentRoute () {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find(c => c.name === this.$options.name) !== undefined
    }
  },
  methods: {
    valueFormatter,
    tableEntitiesMapper (entities, definition) {
      return entities
        .map(e => {
          return Object.assign({}, e, {
            $routerLink: {
              name: `${this.$options.name}.Detail`,
              params: {
                [this.eCurrent.paramKey]: e.id
              }
            }
          })
        })
    },
    tableRowClicked (entity) {
      this.$router.push(entity.$routerLink)
    },
    formatDate (date) {
      return moment(new Date(date)).format('DD.MM.YYYY, HH:mm:ss')
    }
  },
  created () {
    TableHelper.rowClassHook(entity => entity.$routerLink ? 'has-routerlink' : null)
  }
}
</script>

<style lang="scss"></style>
