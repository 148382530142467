<template>
  <state-badge
    class="entity-state"
    :states="entityState.states"
    :data="entityState.data"
    :calculation="entityState.calculation"
    :busy="busy"
  />
</template>

<script>
import { ENTITY_STATES } from '@/assets/js/config/server'
import { ENTITY_STATEBADGE_VARIANTS } from '@/assets/js/config/client'

import StateBadge from '@/components/StateBadge'

export default {
  name: 'EntityState',
  components: {
    StateBadge
  },
  props: {
    entityKey: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: null
    },
    busy: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    STATES () {
      return ENTITY_STATES[this.entityKey] || ENTITY_STATES.default
    },
    entityState () {
      return {
        states: Object.keys(this.STATES).reduce((states, sKey) => Object.assign(states, { [sKey]: { variant: ENTITY_STATEBADGE_VARIANTS[sKey], text: this.$te(`entity.${this.entityKey}.states.${sKey}`) ? this.$t(`entity.${this.entityKey}.states.${sKey}`) : this.STATES[sKey] } }), {}),
        data: { status: this.value },
        calculation: data => Object.keys(this.STATES).find(sKey => this.STATES[sKey] === data.status)
      }
    }
  }
}
</script>

<style lang="scss">
.entity-state {
  white-space: break-spaces;
}
</style>
