<template>
  <div>
    <b-table
      :busy="is.loading"
      :fields="tableData.fields"
      :items="tableData.items"
      striped
      hover
      show-empty
      sort-icon-left
    >
      <template #table-busy>
        <div class="text-center">
          <loader type="dots" inline scale="4"/>
          <div>{{ $t('components.table.busy') }}</div>
        </div>
      </template>

      <template #empty>
        <p class="my-2 text-center">{{ $t('components.table.empty') }}</p>
      </template>

      <template #cell(function)="data">
        <control-select :id="`${data.item.id}-function`" class="mb-0" size="sm" v-model="data.value.value" :options="data.value.options" disabled/>
      </template>

      <template #cell(role)="data">
        <control-select :id="`${data.item.id}-role`" class="mb-0" size="sm" v-model="data.value.value" :options="data.value.options" disabled/>
      </template>

      <template #cell(isMainContact)="data">
        <control-checkbox :id="`${data.item.id}-isMainContact`" class="mb-0" v-model="data.value" switch disabled/>
      </template>

      <template #cell($kill)="data">
        <b-button variant="warning" size="sm" class="text-nowrap" @click="openKillInvitationConfirmation(data.item)"><b-icon icon="trash"/> {{ $t('components.entity.userlocations.invitationstable.item.kill.button') }}</b-button>
      </template>
    </b-table>

    <b-modal
      :id="invitationKillConfirmationModal.id"
      v-model="invitationKillConfirmationModal.show"
      size="md"
      centered
      scrollable
      no-fade
      header-bg-variant="warning"
      header-text-variant="black"
    >
      <template #modal-header-close><b-icon icon="close"/></template>
      <template #modal-title>{{ $t('components.entity.userlocations.invitationstable.modal.header.title') }}</template>

      <template v-if="invitationKillConfirmationModal.show">
        <i18n path="components.entity.userlocations.invitationstable.modal.body.confirmation" tag="p">
          <template #br><br></template>
          <template #email><b-badge variant="info">{{ invitationKillConfirmationModal.item.email }}</b-badge></template>
          <template #location><b-badge variant="light">{{ location.name }}</b-badge></template>
        </i18n>
      </template>

      <template #modal-footer>
        <b-button variant="light" size="sm" @click="closeKillInvitationConfirmation">{{ $t('components.entity.userlocations.invitationstable.modal.footer.button.abort') }}</b-button>
        <b-button variant="danger" size="sm" @click="killInvitation"><b-icon icon="trash"/> {{ $t('components.entity.userlocations.invitationstable.modal.footer.button.confirm') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_ALL } from '@/assets/js/config/client'

import Table from '@/assets/js/helper/table'

import ControlSelect from '@/components/form/ControlSelect'
import ControlCheckbox from '@/components/form/ControlCheckbox'

export default {
  name: 'UserlocationInvitesTable',
  components: {
    ControlSelect,
    ControlCheckbox
  },
  props: {
    entityId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      table: {
        options: {
          fields: {
            includes: ['$kill'],
            excludes: [],
            sorting: ['email', 'invitedBy', 'function', 'role', /* 'isMainContact', */ '$kill'],
            sortable: [CLASS_TABLE_FIELD_KEY_ALL],
            labelKey: 'components.entity.userlocations.invitationstable.label.{field}',
            label: {
              $kill: ''
            },
            variant: {},
            class: {
              isMainContact: 'cell-min cell-align-center',
              $kill: 'cell-min'
            },
            thClass: {},
            tdClass: {}
          }
        }
      },
      invitationKillConfirmationModal: {
        id: 'invitationKillConfirmationModal',
        show: false,
        item: null
      }
    }
  },
  computed: {
    location () {
      return this.$store.getters['locations/getEntity'](this.entityId)
    },
    is () {
      return this.$store.getters['userlocations/is']
    },
    definition () {
      return this.$store.getters['userlocations/definition']
    },
    invitations () {
      return this.$store.getters['userlocations/getInvitations']
    },
    tableEntities () {
      return this.invitations
        .map(INVITATION => {
          const FUNCTION_DEFINITION = this.definition.$properties.function || {}
          const FUNCTION_OPTION_KEYS = (FUNCTION_DEFINITION.settings || {}).selectValues || []
          const FUNCTION_TRANSLATIONS = ((FUNCTION_DEFINITION.translations || {})[this.$i18n.locale] || {}).selectValues || []

          const ROLE_DEFINITION = this.definition.$properties.role || {}
          const ROLE_OPTION_KEYS = (ROLE_DEFINITION.settings || {}).selectValues || []
          const ROLE_TRANSLATIONS = ((ROLE_DEFINITION.translations || {})[this.$i18n.locale] || {}).selectValues || []

          return {
            _invitation: INVITATION,
            id: INVITATION.id,
            email: INVITATION.email,
            invitedBy: INVITATION.invitedBy,
            function: {
              value: INVITATION.function,
              options: FUNCTION_OPTION_KEYS.map(oKey => ({ value: oKey, text: FUNCTION_TRANSLATIONS[oKey] }))
            },
            role: {
              value: INVITATION.role,
              options: ROLE_OPTION_KEYS.map(oKey => ({ value: oKey, text: ROLE_TRANSLATIONS[oKey] }))
            },
            isMainContact: INVITATION.isMainContact || false
          }
        })
    },
    tableData () {
      return new Table(this.tableEntities, this.table.options.fields)
    }
  },
  methods: {
    getInvitations () {
      this.$store.dispatch('userlocations/getInvitations', this.entityId)
    },
    setTableLabels () {
      if (this.definition.properties.length > 0) {
        this.table.options.fields.label = Object.assign(this.table.options.fields.label, this.definition.properties.reduce((labels, p) => Object.assign(labels, { [p.name]: p.translations[this.$i18n.locale].name }), {}))
      }
    },
    killInvitation (item) {
      this.$store.dispatch('userlocations/removeInvitation', {
        locationId: this.entityId,
        invitation: this.invitationKillConfirmationModal.item
      })
      this.closeKillInvitationConfirmation()
    },
    openKillInvitationConfirmation (item) {
      this.invitationKillConfirmationModal.item = item
      this.invitationKillConfirmationModal.show = true
    },
    closeKillInvitationConfirmation () {
      this.invitationKillConfirmationModal.show = false
      this.invitationKillConfirmationModal.item = null
    }
  },
  created () {
    this.getInvitations()
    this.setTableLabels()
  },
  watch: {
    definition: {
      deep: true,
      handler () {
        this.setTableLabels()
      }
    }
  }
}
</script>

<style lang="scss"></style>
