<template>
  <div>
    <loader v-if="!this.userDefinition && !this.locationDefinition && !this.user.loaded && !this.location.loaded"/>
    <template v-else>
    <div class="order-configuration">
      <div class="configuration-title">
        <b-row align-v="center">
          <b-col>
            <h2>{{ $t('components.order.configuration.addresses.title') }}</h2>
          </b-col>
        </b-row>
      </div>
      <dl class="configuration-list">
        <template>
          <dt>{{ $t('components.order.configuration.addresses.delivery.title') }}</dt>
          <dd>
            <address-line :address="configuration.combinedDeliveryAddress" inline/>
          </dd>
        </template>
      </dl>

      <div class="configuration-title">
        <b-row align-v="center">
          <b-col>
            <h2>{{ $t('components.order.configuration.user.title') }}</h2>
          </b-col>

          <b-col cols="auto">
            <b-link :to="{ name: 'Users.Detail', params: { userid: configuration.user.id } }">{{ $t('components.order.configuration.user.detaillink') }}</b-link>
          </b-col>
        </b-row>
      </div>

      <dl v-if="!userIs.loading" class="configuration-list">
        <template v-for="property in user">
          <template v-if="property.visible && property.control.value">
            <dt :key="`title-${property.name}`">{{ property.control.label }}</dt>
            <dd :key="`value-${property.name}`" class="text-wrap-word">{{ valueFormatter(userDefinition, property.name, property.control.value).value }}</dd>
          </template>
        </template>
      </dl>

      <div class="configuration-title">
        <b-row align-v="center">
          <b-col>
            <h2>{{ $t('components.order.configuration.location.title') }}</h2>
          </b-col>

          <b-col cols="auto">
            <b-link :to="{ name: 'Locations.Detail', params: { locationid: configuration.location.id } }">{{ $t('components.order.configuration.location.detaillink') }}</b-link>
          </b-col>
        </b-row>
      </div>

      <dl v-if="!this.locationIs.loading" class="configuration-list">
        <template v-for="property in location">
          <template v-if="property.visible && property.control.value">
            <dt :key="`title-${property.name}`">{{ property.control.label }}</dt>
            <dd :key="`value-${property.name}`" class="text-wrap-word">{{ valueFormatter(locationDefinition, property.name, property.control.value).value }}</dd>
          </template>
        </template>
      </dl>
    </div>
  </template>
  </div>
</template>

<script>
import { valueFormatter, controlMapper } from '@/assets/js/helper/entity'

import AddressLine from '@/components/order/AddressLine'
import Loader from '@/components/Loader'

export default {
  name: 'OrderConfiguration',
  components: {
    AddressLine,
    Loader
  },
  props: {
    configuration: {
      type: Object,
      required: true
    }
  },
  computed: {
    userIs () {
      return this.$store.getters['users/is']
    },
    userDefinition () {
      return this.$store.getters['users/definition']
    },
    user () {
      return controlMapper(this.userDefinition, this.$i18n.locale, [], this.configuration?.user)
    },
    locationIs () {
      return this.$store.getters['locations/is']
    },
    locationDefinition () {
      return this.$store.getters['locations/definition']
    },
    location () {
      return controlMapper(this.locationDefinition, this.$i18n.locale, [], this.configuration?.location)
    }
  },
  methods: {
    valueFormatter
  },
  created () {
    this.$store.dispatch('users/getDefinition')
  }
}
</script>

<style lang="scss">
$orderconfiguration-title-padding-y: $spacer * 0.65 !default;
$orderconfiguration-title-padding-x: $spacer * 1 !default;
$orderconfiguration-title-bg: $gray-200 !default;
$orderconfiguration-title-font-size: inherit !default;
$orderconfiguration-title-color: inherit !default;

$orderconfiguration-list-gap: $spacer * 3 !default;
$orderconfiguration-list-padding-y: $spacer * 0.5 !default;
$orderconfiguration-list-padding-x: $orderconfiguration-title-padding-x !default;
$orderconfiguration-list-border: $border-width solid $border-color !default;
$orderconfiguration-list-title-font-size: $font-size-sm !default;
$orderconfiguration-list-title-font-weight: inherit !default;
$orderconfiguration-list-title-color: $dark !default;
$orderconfiguration-list-data-font-size: inherit !default;
$orderconfiguration-list-data-font-weight: inherit !default;
$orderconfiguration-list-data-color: inherit !default;
$orderconfiguration-list-data-content-gap: $spacer * 0.3 !default;

.order-configuration {
    .summary-title,
    .configuration-title {
      margin: 0;
      padding: $orderconfiguration-title-padding-y $orderconfiguration-title-padding-x;
      background-color: $orderconfiguration-title-bg;
      font-size: $orderconfiguration-title-font-size;
      color: $orderconfiguration-title-color;

      h1, h2, h3, h4, h5, h6 {
        margin: 0;
        font-size: inherit;
      }
    }

    .summary-list {
      margin-bottom: $orderconfiguration-list-gap;
      border-bottom: $orderconfiguration-list-border;

      > div {
        padding: 0 $orderconfiguration-list-padding-x;

        &.summary-separator {
          padding: $orderconfiguration-list-padding-y 0;

          &:first-child { padding-top: 0; }
          &:last-child { padding-top: 0; }

          hr {
            margin: 0;
            border-color: nth($orderconfiguration-list-border, 3);
          }
        }

        &.summary-price {
          &.is-total {
            margin-top: $orderconfiguration-list-padding-y;
            padding: $orderconfiguration-list-padding-y $orderconfiguration-list-padding-x;
            border-top-color: nth($orderconfiguration-list-border, 3);
          }
        }
      }
    }

    .configuration-list {
      @include make-row(0);
      margin-bottom: $orderconfiguration-list-gap;
      border-top: $orderconfiguration-list-border;

      dt,
      dd {
        @include make-col-ready(0);
        margin: 0;
        padding: $orderconfiguration-list-padding-y $orderconfiguration-list-padding-x;
        border-bottom: $orderconfiguration-list-border;
      }

      dt {
        @include make-col(3);
        font-size: $orderconfiguration-list-title-font-size;
        font-weight: $orderconfiguration-list-title-font-weight;
        color: $orderconfiguration-list-title-color;
      }

      dd {
        @include make-col(9);
        font-size: $orderconfiguration-list-data-font-size;
        font-weight: $orderconfiguration-list-data-font-weight;
        color: $orderconfiguration-list-data-color;

        > * {
          margin-bottom: $orderconfiguration-list-data-content-gap;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>
