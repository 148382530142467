<template>
  <div class="entity-requiredactions" @click="onClick">
    <router-link class="requiredactions-link" :to="to" ref="link"></router-link>

    <div class="requiredactions-data">
      <div class="requiredactions-entity">
        <div class="entity-id">
          <b-badge variant="dark">{{ entity.id }}</b-badge>
        </div>

        <div class="entity-name">{{ getLabel() }}</div>
      </div>

      <div class="requiredactions-actions">
        <b-badge v-for="(action, aIndex) in entity.requiredactions" :key="aIndex" class="actions-badge">{{ action }}</b-badge>
      </div>
    </div>
  </div>
</template>

<script>
import string from '@/assets/js/helper/string'
import { entityLabelFormatter } from '@/assets/js/helper/entity'

export default {
  name: 'EntityRequiredActions',
  props: {
    entityKey: {
      type: String,
      required: true
    },
    entity: {
      type: Object,
      required: true
    },
    configuration: {
      type: Object
    }
  },
  computed: {
    to () {
      return {
        name: `${string.capitalize(this.entityKey)}.Detail`,
        params: {
          [`${string.singularize(this.entityKey)}id`]: this.entity.id
        }
      }
    }
  },
  methods: {
    onClick (e) {
      if (e.target !== this.$refs.link.$el) this.$refs.link.$el.dispatchEvent(new MouseEvent('click', { ctrlKey: e.ctrlKey }))
    },
    getLabel () {
      if (this.configuration && this.configuration.label) {
        return entityLabelFormatter({ labelFormat: this.configuration.label }, this.entity)
      }

      return this.entity.name
    }
  }
}
</script>

<style lang="scss">
$requiredactions-padding-y: $table-cell-padding * 0.8 !default;
$requiredactions-padding-x: $table-cell-padding * 0.5 !default;
$requiredactions-bg: transparent !default;
$requiredactions-accent-bg: $table-accent-bg !default;
$requiredactions-border: $border-width solid darken($border-color, 5%) !default;

$requiredactions-entity-gap: $spacer * 0.5 !default;
$requiredactions-entity-border: $border-width solid lighten($border-color, 5%) !default;
$requiredactions-entity-name-font-size: $font-size-base !default;
$requiredactions-entity-name-font-weight: $font-weight-bold !default;

$requiredactions-actions-badge-bg: $light !default;

$requiredactions-hover-bg: $table-hover-bg !default;
$requiredactions-hover-actions-badge-bg: $warning !default;

.entity-requiredactions {
  position: relative;
  padding: $requiredactions-padding-y $requiredactions-padding-x;
  background-color: $requiredactions-bg;
  border-top: $requiredactions-border;
  border-bottom: $requiredactions-border;
  cursor: pointer;

  &:nth-of-type(#{$table-striped-order}) {
    background-color: $requiredactions-accent-bg;
  }

  + .entity-requiredactions {
    border-top: 0 none;
  }

  .requiredactions-link {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .requiredactions-data {
    position: relative;
    z-index: 2;

    .requiredactions-entity {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: $requiredactions-entity-gap;

      .entity-id {
        order: 2;
      }

      .entity-name {
        order: 1;
        font-size: $requiredactions-entity-name-font-size;
        font-weight: $requiredactions-entity-name-font-weight;
      }
    }

    .requiredactions-actions {
      .actions-badge {
        background-color: $requiredactions-actions-badge-bg;
      }
    }
  }

  &:hover {
    background-color: $requiredactions-hover-bg;

    .requiredactions-data {
      .requiredactions-actions {
        .actions-badge {
          background-color: $requiredactions-hover-actions-badge-bg;
        }
      }
    }
  }
}
</style>
