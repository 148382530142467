<template>
  <footer role="contentinfo">
    <b-container fluid class="footer-inner">
      <nav class="navigation-footer">
        <!-- <ul>
          <li>
            <a href="legal" target="_blank">{{ $t('layout.footer.navigation.legal.text') }}</a>
          </li>
          <li>
            <a href="imprint" target="_blank">{{ $t('layout.footer.navigation.imprint.text') }}</a>
          </li>
        </ul> -->
      </nav>

      <div class="made-by">
        <span>made by </span>
        <b-link href="//bambit.ch" target="_blank">
          {{ $t('layout.footer.logo.bambit.company') }}
          <span class="sr-only">{{ $t('layout.footer.logo.bambit.screenreader') }}</span>
        </b-link>
        <span class="text-light"> - {{ version }} | <span class="copyright">&copy; 2021</span></span>
      </div>
    </b-container>
  </footer>
</template>

<script>
import { version } from '@/../package.json'

export default {
  name: 'FooterMain',
  data () {
    return {
      version
    }
  }
}
</script>

<style lang="scss">
$footer-padding-y: $spacer * 0.15 !default;
$footer-bg: $gray-800 !default;
$footer-color: color-yiq($gray-800) !default;

$footer-copyright-font-size: 10px !default;

$footer-navigation-link-font-size: $font-size-sm !default;
$footer-navigation-link-gap: $spacer !default;

$footer-bambit-font-size: $footer-copyright-font-size !default;
$footer-bambit-color: #8cc047 !default;

footer[role="contentinfo"] {
  .footer-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: $footer-padding-y;
    padding-bottom: $footer-padding-y;
    background-color: $footer-bg;
    color: $footer-color;

    .copyright {
      font-size: $footer-copyright-font-size;
    }

    .navigation-footer {
      ul {
        margin: 0 $footer-navigation-link-gap * -0.5;
        padding: 0;
        list-style: none;
        font-size: $footer-navigation-link-font-size;
        @include clearfix();

        li {
          float: left;
          margin-left: $footer-navigation-link-gap * 0.5;
          margin-right: $footer-navigation-link-gap * 0.5;
        }
      }
    }

    .made-by {
      font-size: $footer-bambit-font-size;
      color: inherit;
      text-decoration: none;
      white-space: nowrap;

      a {
        display: inline-block;
        font-weight: $font-weight-bold;
        color: $footer-bambit-color;
        vertical-align: baseline;
      }
    }
  }
}
</style>
