<template>
  <div class="entity-entry-table">
    <b-table
      id="EntityEntryTable"
      :busy="is.loading"
      :fields="entitiesTable.fields"
      :items="entitiesTable.items"
      striped
      hover
      show-empty
      :tbody-tr-class="table.helper.rowClass"

      sort-icon-left
      :no-local-sorting="searchable"
      :sort-by.sync="table.sort.by"
      :sort-desc.sync="table.sort.desc"
      @sort-changed="changeSort"

      @head-clicked="table.events.headClicked"
      @row-clicked="table.events.rowClicked"
      @row-contextmenu="table.events.rowContextMenu"
      @row-dblclicked="table.events.rowDblClicked"
      @row-middle-clicked="table.events.rowMiddleClicked"
      @row-hovered="table.events.rowHovered"
      @row-unhovered="table.events.rowUnhovered"
    >
      <template #table-busy>
        <div class="text-center">
          <loader type="dots" inline scale="4"/>
          <div>{{ $t('components.table.busy') }}</div>
        </div>
      </template>

      <template #empty>
        <p class="my-2 text-center">{{ $t('components.table.empty') }}</p>
      </template>

      <template #emptyfiltered>
        <p class="my-2 text-center">{{ $t('components.table.emptyfiltered') }}</p>
      </template>

      <template v-for="slotName in $cellSlots" #[slotName]="data">
        <slot :name="slotName" :cell="data" :definition="definition">
          <div :key="slotName" v-html="valueFormatter(definition, data.field.key, data.value).html"></div>
        </slot>
      </template>

      <template #cell(status)="data">
        <entity-state :entityKey="entityKey" :value="data.value"/>
      </template>
    </b-table>

    <b-pagination
      v-model="statistics.page.number"
      :per-page="statistics.page.size"
      :total-rows="statistics.total.items"
      :disabled="statistics.total.pages <= 1"
      align="center"
      aria-controls="EntityEntryTable"
      @change="getPage"
    />
  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_UNKNOWN } from '@/assets/js/config/client'

import Table, { OPTIONS_BASE, TableHelper } from '@/assets/js/helper/table'
import { fieldToKeyConverter } from '@/assets/js/helper/search'
import { valueFormatter } from '@/assets/js/helper/entity'

import EntityState from '@/components/entity/State'

export default {
  name: 'EntityEntryTable',
  components: {
    EntityState
  },
  props: {
    entityKey: {
      type: String,
      required: true
    },
    entityBy: {
      type: Object,
      default: () => ({ name: null, id: null })
    },
    tableSettings: {
      type: Object,
      required: true
    },
    searchable: {
      type: Boolean,
      default: false
    },
    initialFilters: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    const tableOptions = this.tableSettings.options || {}
    const tableOptionFields = tableOptions.fields || {}

    return {
      table: {
        helper: this.tableSettings.helper || TableHelper,
        mapper: this.tableSettings.mapper || ((entities, definition) => entities),
        sort: {
          by: null,
          desc: false
        },
        options: Object.assign(tableOptions, {
          fields: Object.keys(OPTIONS_BASE).reduce((fieldOptions, fKey) => Object.assign(fieldOptions, { [fKey]: tableOptionFields[fKey] || OPTIONS_BASE[fKey] }), {})
        }),
        events: Object.assign({
          headClicked: () => {},
          rowClicked: () => {},
          rowContextMenu: () => {},
          rowDblClicked: () => {},
          rowMiddleClicked: () => {},
          rowHovered: () => {},
          rowUnhovered: () => {}
        }, this.tableSettings.events)
      }
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.entityKey}/is`]
    },
    definition () {
      return this.$store.getters[`${this.entityKey}/definition`]
    },
    statistics () {
      return this.$store.getters[`${this.entityKey}/statistics`]
    },
    sorts () {
      return (this.searchable ? this.$store.getters[`${this.entityKey}/sorts`] : [])
        .map(s => Object.assign({ key: fieldToKeyConverter(s.field) }, s))
        .sort((a, b) => a.sortOrder - b.sortOrder)
    },
    entities () {
      return this.is.loading ? [] : this.table.mapper(this.$store.getters[`${this.entityKey}/getUnwrapped`], this.definition)
    },
    entitiesTable () {
      return new Table(this.entities, this.table.options.fields)
    },
    $cellSlots () {
      return this.table.options.fields.sorting.map(f => `cell(${f})`)
    }
  },
  methods: {
    valueFormatter,
    init () {
      if (this.searchable) {
        this.$store.dispatch(`${this.entityKey}/initSearch`, { filters: this.initialFilters })
          .then(() => {
            this.setTableOptions()
          })
      } else {
        this.$store.dispatch(`${this.entityKey}/get`, { by: this.entityBy })
          .then(() => {
            this.setTableLabels()
          })
      }
    },
    setTableOptions () {
      if (this.searchable) {
        const DEFAULT_SORT = this.sorts.find(s => s.isDefault) || {}

        const FIELD_KEYS = this.sorts.map(d => d.key)
        const FIELD_LABELS = this.sorts.reduce((labels, d) => Object.assign(labels, { [d.key]: d.displayName }), {})
        const FIELD_UNKNOWN_INDEX = Math.max(this.table.options.fields.sorting.findIndex(s => s === CLASS_TABLE_FIELD_KEY_UNKNOWN), 0)
        const SORTING_BEFORE_UNKNOWN = this.table.options.fields.sorting.slice(0, FIELD_UNKNOWN_INDEX)
        const SORTING_AFTER_UNKNOWN = this.table.options.fields.sorting.slice(FIELD_UNKNOWN_INDEX + 1)

        this.table.sort.by = DEFAULT_SORT.key || null
        this.table.sort.desc = DEFAULT_SORT.isDescending || false
        this.table.options.fields.sorting = [].concat(SORTING_BEFORE_UNKNOWN, FIELD_KEYS, SORTING_AFTER_UNKNOWN)
        this.table.options.fields.sortable = FIELD_KEYS
        this.table.options.fields.label = FIELD_LABELS
      }
    },
    setTableLabels () {
      if (!this.searchable) {
        this.table.options.fields.label = this.definition.properties
          .reduce((labels, p) => Object.assign(labels, { [p.name]: p.translations[this.$i18n.locale].name }), {})
      }
    },
    getPage (number) {
      if (this.searchable) {
        this.$store.dispatch(`${this.entityKey}/getSearchPage`, { number })
      } else {
        this.$store.dispatch(`${this.entityKey}/getPage`, { by: this.entityBy, number })
      }
    },
    changeSort (tableSorting) {
      if (this.searchable) {
        const sort = this.sorts.find(s => s.key === tableSorting.sortBy)
        if (sort) this.$store.dispatch(`${this.entityKey}/setActiveSort`, { sortId: sort.id })
      }
    }
  },
  created () {
    this.init()
  },
  watch: {
    definition: {
      deep: true,
      handler () {
        this.setTableLabels()
      }
    }
  }
}
</script>

<style lang="scss"></style>
