<template>
  <b-badge class="state-badge" :variant="state.variant">
    <loader v-if="busy" type="dots" inline/>
    <template v-else>{{ state.text }}</template>
  </b-badge>
</template>

<script>
export default {
  name: 'StateBadge',
  props: {
    states: {
      type: Object,
      required: true
    },
    data: {
      type: [String, Number, Boolean, Array, Object, Function, Promise],
      required: true
    },
    calculation: {
      type: Function,
      required: true
    },
    busy: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    stateKey () {
      return this.calculation(this.data)
    },
    state () {
      return this.states[this.stateKey] || {}
    }
  }
}
</script>

<style lang="scss">
.state-badge {}
</style>
