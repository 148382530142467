<template>
  <div class="dev-group">
    <h2 class="group-title">SCSS Variables</h2>
    <pre>{{ variables }}</pre>
  </div>
</template>

<script>
import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

export default {
  name: 'DevVariables',
  data () {
    return {
      variables: SCSS_VARIABLES
    }
  }
}
</script>
