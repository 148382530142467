<template v-if="this.label">
  <b-badge class="orderstate-badge" :variant="colorcode">
    <template>{{ this.label }}</template>
  </b-badge>
</template>

<script>
import { ORDER_ORDERSTATE_COLORCODES } from '@/assets/js/config/client'

export default {
  name: 'OrderStateBadge',
  props: {
    label: {
      type: String,
      required: false
    },
    statename: {
      type: String,
      required: false
    }
  },
  computed: {
    colorcode () {
      return ORDER_ORDERSTATE_COLORCODES[this.statename] || 'dark'
    }
  }
}
</script>

<style lang="scss">

</style>
