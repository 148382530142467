import Vue from 'vue'
import VueI18n from 'vue-i18n'
import LANGUAGES, { DATETIMES, DEFAULT_LANGUAGE, INITIAL_LANGUAGE } from './languages'

Vue.use(VueI18n)

export default new VueI18n({
  locale: INITIAL_LANGUAGE,
  fallbackLocale: DEFAULT_LANGUAGE,
  messages: LANGUAGES,
  dateTimeFormats: DATETIMES
})
