<template>
  <div>
    <!-- if this is active route (e.g. /users) -->
    <template v-if="isCurrentRoute">
      <h2>{{ $t('views.users.professions.title') }}</h2>
      <b-row class="mb-3" align-h="between">
        <b-col cols="auto"></b-col>
        <b-col cols="auto">
          <b-button variant="outline-primary" @click="navigate('Users.Professions.Add')"><b-icon icon="plus"/> {{ $t('views.users.professions.button.addprofession') }}</b-button>
        </b-col>
      </b-row>
      <entity-entry-table :entityKey="eCurrent.key" :entityBy="eParent" :tableSettings="table">
        <template #cell(isApproved)="data">
          <b-icon :icon="data.cell.value ? 'check' : 'close'" :variant="data.cell.value ? 'success' : 'danger'" scale="1.4"/>
        </template>
        <template #cell($kill)="data">
          <div class="d-flex justify-content-end">
            <b-button variant="warning" size="sm" class="text-nowrap" @click="showKillModal(data.cell.item)">
              <b-icon icon="trash"/> {{ $t('views.users.professions.button.deleteprofession') }}
            </b-button>
          </div>
        </template>
      </entity-entry-table>

      <b-modal v-if="showConfirmationModal" v-model="showConfirmationModal" :title="$t('views.users.professions.popup.title')" hide-footer @ok="deleteProfession(currentKillModalItem)">
        <b-alert variant="danger" show v-if="currentKillModalItem.allItemsCount == 1">{{ $t('views.users.professions.popup.error') }}</b-alert>
        <i18n path="views.users.professions.popup.message">
          <template #profession><b-badge variant="info">{{ valueFormatter(definition, 'professionType', currentKillModalItem.professionType).html }}</b-badge></template>
          <template #br><br></template>
        </i18n>
        <div class="text-right">
          <b-button class="mr-2" variant="light" @click="closeToast">{{ $t('views.users.professions.popup.button.cancel') }}</b-button>
          <b-button :disabled="currentKillModalItem.allItemsCount == 1" variant="danger" @click="deleteProfession(currentKillModalItem)">
            <b-icon icon="trash"/>{{ $t('views.users.professions.popup.button.delete') }}
          </b-button>
        </div>
      </b-modal>
    </template>

    <!-- if subroute is active route (e.g. /users/detail) -->
    <section v-else>
      <router-view/>
    </section>
  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_ALL } from '@/assets/js/config/client'

import { TableHelper } from '@/assets/js/helper/table'

import MixinEntityBase from '@/components/mixins/EntityBase'
import EntityEntryTable from '@/components/entity/EntryTable'

import { valueFormatter } from '@/assets/js/helper/entity'

const ENTITY_KEY = 'userprofessions'

export default {
  name: 'Users.Professions',
  mixins: [MixinEntityBase],
  components: {
    EntityEntryTable
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      table: {
        helper: TableHelper,
        mapper: this.tableEntitiesMapper,
        options: {
          fields: {
            includes: ['$kill'],
            excludes: [],
            sorting: ['professionType', 'field', 'isApproved', 'status', '$kill'],
            sortable: [CLASS_TABLE_FIELD_KEY_ALL],
            labelKey: `views.${this.$options.name.toLowerCase()}.table.label.{field}`,
            label: { },
            variant: {},
            class: {
              isApproved: 'cell-min cell-align-center',
              status: 'cell-min cell-align-right',
              $kill: 'cell-min cell-align-right'
            },
            thClass: {},
            tdClass: {}
          }
        },
        events: {
          rowClicked: this.tableRowClicked
        }
      },
      showConfirmationModal: false,
      currentKillModalItem: null
    }
  },
  computed: {
    isCurrentRoute () {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find(c => c.name === this.$options.name) !== undefined
    },
    definition () {
      return this.$store.getters[`${this.eCurrent.key}/definition`]
    }
  },
  methods: {
    valueFormatter,
    tableEntitiesMapper (entities) {
      const entityCount = (entities || []).length
      return entities
        .map(e => Object.assign({
          $routerLink: {
            name: `${this.$options.name}.Detail`,
            params: {
              [this.eCurrent.paramKey]: e.id
            }
          },
          allItemsCount: entityCount
        }, e))
    },
    tableRowClicked (entity) {
      this.$router.push(entity.$routerLink)
    },
    showKillModal (item) {
      this.currentKillModalItem = item
      this.showConfirmationModal = true
    },
    deleteProfession (item) {
      this.$store.dispatch(`${this.eCurrent.key}/removeEntity`, item).catch(error => console.log(error))
      this.closeToast()
    },
    closeToast () {
      this.showConfirmationModal = false
    }
  },
  created () {
    TableHelper.rowClassHook(entity => entity.$routerLink ? 'has-routerlink' : null)
  }
}
</script>

<style lang="scss"></style>
