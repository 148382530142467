<template>
<div v-if="this.order">
  <div class="orderactions">
    <h2>{{ $t('components.order.actions.title') }}</h2>

    <div v-if="hasDiasProducts" class="action action-sendtodias">
      <h4 class="h3">{{ $t('components.order.actions.sendtodias.title') }}</h4>
      <div v-if="!isTransmittedToDias">
        <div>{{ $t('components.order.actions.sendtodias.nottransmitted') }}</div>
        <div class="mt-3">
          <b-button class="action-button mr-2" variant="success" @click="transmitToDiasAsOrder()"><loader :inline="true" v-if="isChangingTransmissionState"/><b-icon v-else icon="arrow-right"/> {{ $t('components.order.actions.sendtodias.buttonorder') }}</b-button>
          <b-button class="action-button mr-2" variant="info" @click="transmitToDiasAsOffer()"><loader :inline="true" v-if="isChangingTransmissionState"/><b-icon v-else icon="arrow-right"/> {{ $t('components.order.actions.sendtodias.buttonoffer') }}</b-button>
          <b-button class="action-button mr-2" variant="warning" @click="setDiasOrdersTransmitted()"><loader :inline="true" v-if="isChangingTransmissionState"/><b-icon v-else icon="arrow-right"/> {{ $t('components.order.actions.sendtodias.buttondonttransmit') }}</b-button>
        </div>
      </div>
      <div v-else>
        <b-button class="action-button" variant="dark">{{ $t('components.order.actions.sendtodias.transmittedat') }} {{ transmittedtoDiasDate }}</b-button>
      </div>
    </div>

    <div v-if="hasRuefenachtProducts" class="action action-sendtodruefenacht">
      <h4 class="h3">{{ $t('components.order.actions.sendtoruefenacht.title') }}</h4>
      <div>
         <div v-if="!isRuefenachtTransmitted">
          <div>{{ $t('components.order.actions.sendtoruefenacht.nottransmitted') }}</div>
          <div class="mt-3">
           <b-button class="action-button mr-2" variant="success" @click="transmitToRuefenachtAsOrder()"><loader :inline="true" v-if="isChangingToRuefenachtTransmissionState"/><b-icon v-else icon="arrow-right"/> {{ $t('components.order.actions.sendtoruefenacht.generatemail') }}</b-button>
           <b-button class="action-button mr-2" variant="warning" @click="setRuefenachtAstransmitted()"><loader :inline="true" v-if="isChangingToRuefenachtTransmissionState"/><b-icon v-else icon="arrow-right"/> {{ $t('components.order.actions.sendtoruefenacht.button') }}</b-button>
          </div>
        </div>
        <div v-else>
          <b-button class="action-button" variant="dark">{{ $t('components.order.actions.sendtoruefenacht.transmittedat') }} {{ transmittedRuefenachtDate }}</b-button>
        </div>
      </div>
    </div>

    <div v-if="hasOnlyUndefinedWarehouseProducts" class="action action-sendtodruefenacht">
      <h4 class="h3">{{ $t('components.order.actions.notransmission.title') }}</h4>
      <div>
        <div> <b-alert variant="danger" show>{{ $t('components.order.actions.notransmission.reason') }}</b-alert></div><br />
        <div v-if="!isUndefinedWarehouseTransmitted">
          <b-button class="action-button" variant="success" @click="setUndefinedWarehouseAstransmitted()"><loader :inline="true" v-if="isChangingNoWarehouseTransmissionState"/><b-icon v-else icon="arrow-right"/> {{ $t('components.order.actions.nowarehouse.button') }}</b-button>
        </div>
        <div v-else>
          <b-button class="action-button" variant="dark">{{ $t('components.order.actions.nowarehouse.transmittedat') }} {{ transmittedUndefinedWarehouseDate }}</b-button>
        </div>
      </div>
    </div>

     <div v-if="hasSomeUndefinedWarehouseProducts" class="action action-sendtodruefenacht">
      <h4 class="h3">{{ $t('components.order.actions.nowarehouse.title') }}</h4>
      <div v-if="!isUndefinedWarehouseTransmitted">
            <div> <b-alert variant="danger" show>{{ $t('components.order.actions.nowarehouse.reason') }}</b-alert></div><br />
        <b-button class="action-button" variant="success" @click="setUndefinedWarehouseAstransmitted()"><loader :inline="true" v-if="isChangingNoWarehouseTransmissionState"/><b-icon v-else icon="arrow-right"/> {{ $t('components.order.actions.nowarehouse.button') }}</b-button>
      </div>
      <div v-else>
        <b-button class="action-button" variant="dark">{{ $t('components.order.actions.nowarehouse.transmittedat') }} {{ transmittedUndefinedWarehouseDate }}</b-button>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { ORDER_TRANSMISSIONTYPES, ORDER_TRANSMISSIONSTATES, WAREHOUSES } from '@/assets/js/config/server'
import Loader from '@/components/Loader'

export default {
  name: 'OrderActions',
  components: {
    Loader
  },
  props: {
    order: {
      type: Object,
      default: null
    },
    entityKey: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isChangingTransmissionState: false,
      isChangingNoWarehouseTransmissionState: false,
      isChangingToRuefenachtTransmissionState: false
    }
  },
  computed: {
    hasDiasProducts () {
      if (!this.order) {
        return false
      }

      return Object.prototype.hasOwnProperty.call(this.order.transmissions, ORDER_TRANSMISSIONTYPES.externalOrderManagement) && this.hasActiveItemsWithWareHouse(WAREHOUSES.ebipharm)
    },
    hasRuefenachtProducts () {
      if (!this.order) {
        return false
      }

      return Object.prototype.hasOwnProperty.call(this.order.transmissions, ORDER_TRANSMISSIONTYPES.email) && this.hasActiveItemsWithWareHouse(WAREHOUSES.ruefenacht)
    },
    hasOnlyUndefinedWarehouseProducts () {
      if (!this.order) {
        return false
      }
      const items = this.order.executedBasket.items.filter(itm => itm.basketItemState === 'Active')
      return items.length && items.every(item => item.product.warehouse === undefined)
    },
    hasSomeUndefinedWarehouseProducts () {
      if (!this.order) {
        return false
      }

      return Object.prototype.hasOwnProperty.call(this.order.transmissions, ORDER_TRANSMISSIONTYPES.none) && this.hasActiveItemsWithWareHouse(undefined)
    },
    isTransmittedToDias () {
      return this.order.transmissions[ORDER_TRANSMISSIONTYPES.externalOrderManagement].state !== ORDER_TRANSMISSIONSTATES.notTransmitted
    },
    isUndefinedWarehouseTransmitted () {
      return this.order.transmissions[ORDER_TRANSMISSIONTYPES.none].state !== ORDER_TRANSMISSIONSTATES.notTransmitted
    },
    isRuefenachtTransmitted () {
      return this.order.transmissions[ORDER_TRANSMISSIONTYPES.email].state !== ORDER_TRANSMISSIONSTATES.notTransmitted
    },
    transmittedtoDiasDate () {
      return new Date(this.order.transmissions[ORDER_TRANSMISSIONTYPES.externalOrderManagement].timestamp).toLocaleString()
    },
    transmittedUndefinedWarehouseDate () {
      return new Date(this.order.transmissions[ORDER_TRANSMISSIONTYPES.none].timestamp).toLocaleString()
    },
    transmittedRuefenachtDate () {
      return new Date(this.order.transmissions[ORDER_TRANSMISSIONTYPES.email].timestamp).toLocaleString()
    },
    getRuefenachtProducts () {
      return this.order.executedBasket.items.filter(item => item.product.warehouse === WAREHOUSES.ruefenacht && item.basketItemState === 'Active').map(item => ({
        id: item.product.id,
        name: item.product.name,
        quantity: item.quantity,
        deliveryweek: this.getAdditionalConfiguration(item)
      }))
    }
  },
  methods: {
    hasActiveItemsWithWareHouse (warehouse) {
      return this.order.executedBasket.items.some(itm => itm.basketItemState === 'Active' && itm.product.warehouse === warehouse)
    },
    transmitToDiasAsOrder () {
      this.isChangingTransmissionState = true
      this.$store.dispatch(`${this.entityKey}/triggerSetTransmissionState`, {
        entityId: this.order.id,
        transmissionType: ORDER_TRANSMISSIONTYPES.externalOrderManagement,
        transmissionState: ORDER_TRANSMISSIONSTATES.pendingTransmission,
        treatAsOffer: false
      })
        .finally(() => {
          this.isChangingTransmissionState = false
        })
    },
    transmitToDiasAsOffer () {
      this.isChangingTransmissionState = true
      this.$store.dispatch(`${this.entityKey}/triggerSetTransmissionState`, {
        entityId: this.order.id,
        transmissionType: ORDER_TRANSMISSIONTYPES.externalOrderManagement,
        transmissionState: ORDER_TRANSMISSIONSTATES.pendingTransmission,
        treatAsOffer: true
      })
        .finally(() => {
          this.isChangingTransmissionState = false
        })
    },
    setDiasOrdersTransmitted () {
      this.isChangingTransmissionState = true
      this.$store.dispatch(`${this.entityKey}/triggerSetTransmissionState`, {
        entityId: this.order.id,
        transmissionType: ORDER_TRANSMISSIONTYPES.externalOrderManagement,
        transmissionState: ORDER_TRANSMISSIONSTATES.transmitted,
        treatAsOffer: false
      })
        .finally(() => {
          this.isChangingTransmissionState = false
        })
    },
    setUndefinedWarehouseAstransmitted () {
      this.isChangingNoWarehouseTransmissionState = true
      this.$store.dispatch(`${this.entityKey}/triggerSetTransmissionState`, {
        entityId: this.order.id,
        transmissionType: ORDER_TRANSMISSIONTYPES.none,
        transmissionState: ORDER_TRANSMISSIONSTATES.transmitted
      })
        .finally(() => {
          this.isChangingNoWarehouseTransmissionState = false
        })
    },
    transmitToRuefenachtAsOrder () {
      this.isChangingTransmissionState = true
      this.$store.dispatch(`${this.entityKey}/triggerSetTransmissionState`, {
        entityId: this.order.id,
        transmissionType: ORDER_TRANSMISSIONTYPES.email,
        transmissionState: ORDER_TRANSMISSIONSTATES.pendingTransmission,
        treatAsOffer: true
      })
        .finally(() => {
          this.isChangingTransmissionState = false
        })
    },
    setRuefenachtAstransmitted () {
      this.isChangingToRuefenachtTransmissionState = true
      this.$store.dispatch(`${this.entityKey}/triggerSetTransmissionState`, {
        entityId: this.order.id,
        transmissionType: ORDER_TRANSMISSIONTYPES.email,
        transmissionState: ORDER_TRANSMISSIONSTATES.transmitted
      })
        .finally(() => {
          this.isChangingToRuefenachtTransmissionState = false
        })
    },
    getAdditionalConfiguration (item) {
      if (item.configuration.additionalConfigurations.dateStart && item.configuration.additionalConfigurations.dateEnd) {
        return `${this.$options.filters.formatDate(item.configuration.additionalConfigurations.dateStart)} - ${this.$options.filters.formatDate(item.configuration.additionalConfigurations.dateEnd)}`
      }
      return null
    }
  }
}
</script>

<style lang="scss">
$orderactions-orderactions-gap: $spacer * 3 !default;
$orderactions-orderactions-inner-gap: $orderactions-orderactions-gap * 0.5 !default;
$orderactions-orderactions-border: $border-width solid $border-color !default;

$orderactions-action-action-gap: $spacer * 2 !default;
$orderactions-action-action-border: $border-width solid $border-color !default;

$orderactions-action-info-gap: $spacer * 0.5 !default;
$orderactions-action-info-font-size: $font-size-sm !default;
$orderactions-action-info-font-weight: inherit !default;
$orderactions-action-info-color: inherit !default;
$orderactions-action-info-time-font-size: inherit !default;
$orderactions-action-info-time-font-weight: $font-weight-bold !default;
$orderactions-action-info-time-color: inherit !default;

$orderactions-action-description-gap: $spacer !default;
$orderactions-action-description-font-size: $font-size-sm !default;

.orderactions {
  .action {
    .action-info {
      margin-bottom: $orderactions-action-info-gap;
      font-size: $orderactions-action-info-font-size;
      font-weight: $orderactions-action-info-font-weight;
      color: $orderactions-action-info-color;

      span,
      time {
        font-size: $orderactions-action-info-time-font-size;
        font-weight: $orderactions-action-info-time-font-weight;
        color: $orderactions-action-info-time-color;
      }
    }

    .action-description {
      display: inline-block;
      margin-bottom: $orderactions-action-description-gap;
      font-size: $orderactions-action-description-font-size;
    }

    .action-button {}

    + .action {
      margin-top: $orderactions-action-action-gap * 0.5;
      padding-top: $orderactions-action-action-gap * 0.5;
      border-top: $orderactions-action-action-border;
    }
  }

  + .orderactions {
    margin-top: $orderactions-orderactions-gap;
    padding-top: $orderactions-orderactions-inner-gap;
    border-top: $orderactions-orderactions-border;
  }
}
</style>
