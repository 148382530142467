import { validators } from '@/$plugins/vuelidate'

export const STOREMODULE_ENDPOINTMODULE_MAP = {
  customer: 'customer',
  catalog: 'catalog',
  search: 'search',
  orders: 'order'
}

export const ORDER_TRANSMISSIONTYPES = {
  externalOrderManagement: 'TransmitToExternalOrderManagement',
  email: 'TransmitPerEmail',
  none: 'TransmitNotPossible'
}

export const ORDER_TRANSMISSIONSTATES = {
  notTransmitted: 'NotTransmitted',
  pendingTransmission: 'PendingTransmission',
  transmitted: 'Transmitted'
}

export const WAREHOUSES = {
  ebipharm: 'ebi-pharm',
  ruefenacht: 'ruefenacht'
}

export const STOREMODULE_ENDPOINT_MAP = {
  changetracking: 'ChangeTracking',
  services: 'SubscriptionsAndServices',
  locations: 'Locations',
  locationaddresses: 'LocationAddress',
  locationevents: 'LocationEvent',
  users: 'Users',
  useraddresses: 'UserAddress',
  userprofessions: 'Professions',
  userlogins: 'UserLogins',
  userservices: 'UserSubscriptionsAndServices',
  userlocations: 'UserLocations',
  sellingpoints: 'SellingPoints',
  orders: 'Orders'
}

export const FILE_ENDPOINT_DEFAULT_URLS = {
  upload: '/customer/api/Files/create',
  download: '/customer/api/Files/',
  delete: '/customer/api/Files/'
}

export const ROUTENAME_REQUIREDACTION_MAP = {
  locationaddresses: {
    routeName: 'Locations.Addresses',
    requiredActionKey: 'LocationAddress'
  },
  locationusers: {
    routeName: 'Locations.Users',
    requiredActionKey: 'UserLocations'
  },
  useraddresses: {
    routeName: 'Users.Addresses',
    requiredActionKey: 'UserAddress'
  },
  userprofessions: {
    routeName: 'Users.Professions',
    requiredActionKey: 'Professions'
  },
  userlogins: {
    routeName: 'Users.Logins',
    requiredActionKey: 'UserLogins'
  },
  userservices: {
    routeName: 'Users.Services',
    requiredActionKey: 'UserSubscriptionsAndServices'
  }
}

export const SEARCH_FILTER_COMPONENT_TYPES = {
  text: {
    type: 'text'
  },
  multiselect: {
    type: 'multiselect'
  },
  daterange: {
    type: 'daterange',
    controlKeys: {
      from: 'from',
      till: 'till'
    },
    minDate: '0001-01-01'
  },
  geopoint: {
    type: 'geo_point',
    component: 'geopoint'
  },
  unknown: {
    type: 'unknown'
  }
}

export const USER_ROLES = {
  user: 'User',
  admin: 'EbiAdministrator',
  super: 'SuperAdministrator',
  thirdpartyorder: 'ThirdPartyOrder'
}

export const USER_APPROVALSTATES = {
  unapproved: {
    key: 'Unapproved',
    setFromStates: []
  },
  approved: {
    key: 'Approved',
    icon: 'lock-open',
    variant: 'success',
    setFromStates: ['Unapproved', 'Locked']
  },
  locked: {
    key: 'Locked',
    icon: 'lock',
    variant: 'danger',
    setFromStates: ['Approved'],
    needsConfirmation: true
  }
}

export const ENTITY_STATES = {
  default: {
    new: 'New',
    ok: 'Ok',
    openChangeRequests: 'HaveOpenChangeRequests',
    pendingChangeRequests: 'HavePendingChangeRequests'
  }
  /*
    define custom ENTITY_STATES by using the STOREMODULE_ENDPOINT_MAP keys
    e.g.
    changetracking: {
      new: 'Newer',
      ok: 'Okey',
      openChangeRequests: 'EntityHasOpenCRs',
      pendingChangeRequests: 'EntityHasPendingCRs,
      closedChangeRequests: 'EntityHasClosedCRs',
      ...
    }
  */
}

export const CHANGEREQUEST_STATES = {
  open: 'Open',
  replaced: 'Replaced',
  approved: 'Approved',
  declined: 'Declined',
  closed: 'Closed'
}

export const CHANGETRACKING_STATES = {
  created: 'Insert',
  updated: 'Update',
  deleted: 'Delete'
}

export const CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP = {
  input: {
    type: 'StringValueAttribute',
    component: 'control-input',
    baseValue: ''
  },
  integer: {
    type: 'IntValueAttribute',
    component: 'control-input',
    baseValue: ''
  },
  select: {
    type: 'SingleSelectValueAttribute',
    component: 'control-select',
    baseValue: ''
  },
  singlerelation: {
    type: 'SingleRelationValueAttribute',
    component: 'control-select',
    baseValue: ''
  },
  textarea: {
    type: 'MultiLineStringValueAttribute',
    component: 'control-textarea',
    baseValue: ''
  },
  checkbox: {
    type: 'MultiSelectValueAttribute',
    component: 'control-checkbox',
    baseValue: []
  },
  multirelation: {
    type: 'MultiRelationValueAttribute',
    component: 'control-multiselect',
    baseValue: []
  },
  password: {
    type: 'PasswordValueAttribute',
    component: 'control-input',
    baseValue: '',
    controlType: 'password',
    validators: ['requiredValidator'],
    /*
      https://docs.microsoft.com/en-us/aspnet/core/security/authentication/identity-configuration?view=aspnetcore-5.0#password
      Passord Regex:
      ^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]))([a-zA-Z\d]|[^a-zA-Z\d\s]){6,}$
      1 lowercase
      1 uppercase
      1 digit
      1 nonalphanumeric => ~`!@#$%^&*()_-+={}[]|\:;<,>.?/
        but no space-characters \s => space\r\n\t\f\v
      6 digits or longer
    */
    customValidators: {
      lowercase: {
        validation: value => /^(?=.*[a-z]).+$/.test(value)
      },
      uppercase: {
        validation: value => /^(?=.*[A-Z]).+$/.test(value)
      },
      digit: {
        validation: value => /^(?=.*\d).+$/.test(value)
      },
      nonalphanumeric: {
        validation: value => /^(?=.*[^a-zA-Z\d\s]).+$/.test(value)
      },
      nonundefined: {
        validation: value => /^([a-zA-Z\d]|[^a-zA-Z\d\s])+$/.test(value)
      },
      minLength: {
        validation: validators.minLength,
        parameters: 6
      }
    }
  },
  files: {
    type: 'MultiFileValueAttribute',
    component: 'control-files',
    baseValue: [],
    requiresHardChange: true
  },
  boolean: {
    type: 'BooleanValueAttribute',
    component: 'control-checkbox',
    baseValue: false
  },
  datetime: {
    type: 'DateTimeValueAttribute',
    component: 'control-datepicker',
    baseValue: ''
  },
  unknown: {
    type: 'UnknownValueAttribute',
    component: 'control-unknown',
    baseValue: ''
  }
}

export const CONTROL_DISCRIMINATOR_TO_COMPONENT_MAP = Object.keys(CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP).reduce((types, tKey) => Object.assign(types, { [CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP[tKey].type]: Object.assign({}, CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP[tKey], { type: tKey }) }), {})

export const CONTROL_CHECK_TO_DISCRIMINATOR_MAP = {
  equal: {
    type: 'VisibleWhenEqual',
    check: (a, b) => a === b
  }
}

export const CONTROL_DISCRIMINATOR_TO_CHECK_MAP = Object.keys(CONTROL_CHECK_TO_DISCRIMINATOR_MAP).reduce((checks, cKey) => Object.assign(checks, { [CONTROL_CHECK_TO_DISCRIMINATOR_MAP[cKey].type]: Object.assign({}, CONTROL_CHECK_TO_DISCRIMINATOR_MAP[cKey], { type: cKey }) }), {})
