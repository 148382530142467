import Vue from 'vue'

import DevBuild from '@/components/dev/Build'
import DevVue from '@/components/dev/Vue'
import DevVariables from '@/components/dev/Variables'
import DevColors from '@/components/dev/Colors'
import DevIcons from '@/components/dev/Icons'
import DevText from '@/components/dev/Text'

import Loader from '@/components/Loader'

if (process.env.NODE_ENV === 'development') {
  Vue.component('dev-build', DevBuild)
  Vue.component('dev-vue', DevVue)
  Vue.component('dev-variables', DevVariables)
  Vue.component('dev-colors', DevColors)
  Vue.component('dev-icons', DevIcons)
  Vue.component('dev-text', DevText)
} else {
  Vue.component('dev-build', {})
  Vue.component('dev-vue', {})
  Vue.component('dev-variables', {})
  Vue.component('dev-colors', {})
  Vue.component('dev-icons', {})
  Vue.component('dev-text', {})
}

Vue.component('loader', Loader)
