<template v-if="this.item">
  <div :class="['basket-item',basketItemState]">
    <div class="area-separator"/>
    <div class="area-image">
      <img v-if="item.product.image" :src="item.product.image" :alt="item.product.name"/>
      <aspect-ratio v-else ratio="4x3" class="ratio-4x3 is-fallback-image">
        <b-icon icon="images"/>
      </aspect-ratio>
    </div>

    <div class="area-description">
      <p class="mb-0"><span class="item-id">{{item.product.articleNumber ? item.product.articleNumber : item.product.id }}</span></p>
      <p><span class="item-name">{{ item.product.model }}</span> {{ item.product.name }}</p>
      <p v-if="item.product.bundleCount > 0">
      {{ $t('components.order.basket.basketitem.bundlecount_text', { bundleCount: item.product.bundleCount }) }}
      </p>
      <p class="mb-0" v-if="item.configuration.additionalConfigurations.dateStart && item.configuration.additionalConfigurations.dateEnd">
        <span>{{ $t('components.order.basket.basketitem.deliverytimeslot') }} {{item.configuration.additionalConfigurations.dateStart | formatDate}} - {{item.configuration.additionalConfigurations.dateEnd | formatDate}}</span>
      </p>
    </div>

    <div class="area-quantity">
        <span v-if="!editQuantity">{{ itemQuantity }}</span>
        <div class="d-flex justify-content-end" v-if="editQuantity">
          <b-input
            :type="'number'"
            v-model="itemQuantityUpdateValue"
            :autocomplete="'false'"
            class="w-25"
          />
          <b-button :title="$t('components.order.basket.basketitem.edit_button_cancel')" v-if="editQuantity" @click.prevent="editQuantity=false" variant="link"><b-icon variant="danger" icon="close" aria-hidden="true"></b-icon></b-button>
          <b-button :title="$t('components.order.basket.basketitem.edit_button_save')" v-if="editQuantity" @click.prevent="updateQuantity" variant="link"><b-icon variant="success" icon="check" aria-hidden="true"></b-icon></b-button>
        </div>
        <b-button :title="$t('components.order.basket.basketitem.edit_button_edit')" v-if="isEditable && !editQuantity" @click.prevent="editQuantity=true" variant="link"><b-icon icon="b-pencil-fill" aria-hidden="true"></b-icon></b-button>
    </div>

    <div class="area-warehouse">
      <b-badge v-if="item.product.warehouse === 'ebi-pharm'" variant="primary">{{ $t('components.order.basket.basketitem.warehouse_dias') }}</b-badge>
      <b-badge v-else-if="item.product.warehouse === 'ruefenacht'" variant="warning">{{ $t('components.order.basket.basketitem.warehouse_rufenacht') }}</b-badge>
      <b-badge v-else variant="danger">{{ $t('components.order.basket.basketitem.warehouse_missing') }}</b-badge>
    </div>

    <div class="area-actions">
      <b-button :title="$t('components.order.basket.basketitem.confirm_deleted_button_ok')" v-if="isEditable" @click="openModal('deleted')" variant="warning"><b-icon  icon="trash"></b-icon></b-button>
      <b-button :title="$t('components.order.basket.basketitem.confirm_active_button_ok')" v-if="isEditableWithoutState && basketItemState === 'deleted'" @click="openModal('active')" variant="success"><b-icon  icon="reset"></b-icon></b-button>
    </div>

    <div class="area-separator"/>
    <b-modal
      :id="confirmationModal.id"
      v-model="confirmationModal.show"
      size="md"
      centered
      scrollable
      no-fade
      header-bg-variant="warning"
      header-text-variant="black"
      title-tag="h4"
      title-class="align-self-center"
      hide-footer
    >
      <template #modal-header-close><b-icon icon="close" scale="0.6"/></template>
      <template #modal-title>{{$t(`components.order.basket.basketitem.confirm_${confirmationModal.type}_item_title`)}}</template>

      <template v-if="confirmationModal.show">
        <div>
          {{$t(`components.order.basket.basketitem.confirm_${confirmationModal.type}_item`)}}
        </div>

        <div class="d-flex justify-content-end mt-3">
          <div class="btn-list">
            <b-button variant="light" size="sm" @click="closeModal"><slot name="modalAbortButton" :type="confirmationModal.type"/>{{$t(`components.order.basket.basketitem.confirm_${confirmationModal.type}_button_cancel`)}}</b-button>
            <b-button variant="danger" size="sm" @click.prevent="changeStateBasketItem(confirmationModal.type)"><b-icon icon="trash"/> {{$t(`components.order.basket.basketitem.confirm_${confirmationModal.type}_button_ok`)}}</b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

import AspectRatio from '@/components/AspectRatio'

export default {
  name: 'BasketItem',
  components: {
    AspectRatio
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    order: {
      type: Object
    }
  },
  data () {
    return {
      itemQuantityUpdateValue: this.item.quantity,
      confirmationModal: {
        id: `${this.$options.name}_ConfirmationModal`,
        show: false,
        type: 'delete'
      },
      editQuantity: false,
      editableOrderStates: ['Open', 'Executed', 'Ordered', 'Processing']
    }
  },
  computed: {
    itemQuantity () {
      return this.item.quantity
    },
    basketItemState () {
      return this.item.basketItemState?.toLowerCase()
    },
    isEditable () {
      return this.isEditableWithoutState && this.basketItemState === 'active'
    },
    transmissions () {
      return this.order.transmissions
    },
    isEditableWithoutState () {
      let editable = this.editableOrderStates.includes(this.order.orderState)
      if (this.item.product.warehouse) {
        if (this.item.product.warehouse === 'ebi-pharm') {
          editable = editable && this.transmissions.TransmitToExternalOrderManagement && this.transmissions.TransmitToExternalOrderManagement.state === 'NotTransmitted'
        } else if (this.item.product.warehouse === 'ruefenacht') {
          editable = editable && this.transmissions.TransmitPerEmail && this.transmissions.TransmitPerEmail.state === 'NotTransmitted'
        } else {
          editable = false
        }
      } else {
        editable = editable && this.transmissions.TransmitNotPossible && this.transmissions.TransmitNotPossible.state === 'NotTransmitted'
      }
      return editable
    }
  },
  methods: {
    changeStateBasketItem (itemState) {
      this.$store.dispatch('orders/updateOrderItem', {
        orderId: this.order.id,
        basketItemId: this.item.basketItemId,
        data: {
          itemState: itemState.charAt(0).toUpperCase() + itemState.slice(1)
        }
      }).finally(() => {
        this.confirmationModal.show = false
      })
    },
    updateQuantity () {
      this.$store.dispatch('orders/updateOrderItem', {
        orderId: this.order.id,
        basketItemId: this.item.basketItemId,
        data: {
          quantity: this.itemQuantityUpdateValue
        }
      })
      this.editQuantity = false
    },
    openModal (type) {
      this.confirmationModal.type = type
      this.confirmationModal.show = true
    },
    closeModal () {
      this.confirmationModal.show = false
    }
  }
}
</script>

<style lang="scss">
$basketitem-separator-gap-y: $spacer * 0.4 !default;
$basketitem-separator-border: $border-width solid $border-color !default;

$basketitem-image-width: 100px !default;
$basketitem-img-fallback-size: 100% !default;
$basketitem-img-fallback-color: $light !default;

$basketitem-description-name-font-weight: $font-weight-bold !default;
$basketitem-description-name-text-transform: uppercase !default;

$basketitem-configuration-gap-y: 0 !default;
$basketitem-configuration-gap-x: $spacer * 1.2 !default;

$basketitem-totalprice-font-weight: $font-weight-bold !default;

.order-basket {
  .basket-items {
    .basket-item {
      display: contents;

      &.deleted{
        >*{
          text-decoration: line-through;
        }
      }

      .area-separator {
        margin-top: $basketitem-separator-gap-y;
        margin-bottom: $basketitem-separator-gap-y;
        border-top: $basketitem-separator-border;
      }

      .area-image {
        img,
        .is-fallback-image {
          display: block;
          margin: 0;
          width: $basketitem-image-width;
          max-width: none;

          .bi {
            display: block;
            margin: auto;
            width: $basketitem-img-fallback-size;
            height: $basketitem-img-fallback-size;
            color: $basketitem-img-fallback-color;
          }
        }
      }

      .area-description {
        .item-name {
          font-weight: $basketitem-description-name-font-weight;
          text-transform: $basketitem-description-name-text-transform;
        }
      }

      .area-description {
        .item-id {
          font-weight: $basketitem-description-name-font-weight;
          text-transform: $basketitem-description-name-text-transform;
        }
      }
      .area-description {
        .item-bundlecount {
          font-weight: $basketitem-description-name-font-weight;
          text-transform: $basketitem-description-name-text-transform;
        }
      }

      .area-warehouse {
        padding:4px 0;
      }

      .area-configuration {
        .item-configuration {
          display: flex;
          flex-wrap: wrap;
          margin: ($basketitem-configuration-gap-y * -0.5) ( $basketitem-configuration-gap-x * -0.5);

          span {
            display: block;
            padding: ($basketitem-configuration-gap-y * 0.5) ($basketitem-configuration-gap-x * 0.5);
          }
        }
      }

      .area-price,
      .area-totalprice {
        white-space: nowrap;
      }

      .area-totalprice {
        font-weight: $basketitem-totalprice-font-weight;
      }

      + .basket-item {
        .area-separator {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}
</style>
