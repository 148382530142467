<template>
  <div id="app">
    <layout>
      <router-view/>
    </layout>

    <b-toaster :name="apiToasterId"/>
  </div>
</template>

<script>
import { TOASTER_OPTIONS } from '@/assets/js/config/client'

import Layout from '@/views/_shared/Layout'

export default {
  components: {
    Layout
  },
  data () {
    return {
      apiToasterId: TOASTER_OPTIONS.api.id
    }
  }
}
</script>

<style lang="scss">
// Custom Styles
// ---------------------------------

@import '@/assets/css/modules/webfonts.scss';
@import '@/assets/css/modules/text.scss';
@import '@/assets/css/modules/btn-list.scss';
@import '@/assets/css/modules/badge-group.scss';
@import '@/assets/css/modules/badge.scss';
@import '@/assets/css/modules/forms.scss';
@import '@/assets/css/modules/table.scss';

@import '@/assets/css/modules/nprogress.scss';
@import '@/assets/css/modules/toaster.scss';
@import '@/assets/css/modules/module.scss';
@import '@/assets/css/modules/notes.scss';
</style>
