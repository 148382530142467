<template>
  <div>
    <div class="module">
      <div class="module-title">
        <h2>{{ $t('views.users.notes.title') }}</h2>
      </div>

      <ul v-if="notes.length" class="notes">
        <li v-for="note in notes" :key="note.id" class="note">
          <div class="note-fg">
            <div class="note-user">{{ note.userName }}</div>
            <div class="note-message">{{ note.text }}</div>
            <div class="note-datetime"><timeago :datetime="note.created" auto-update/></div>

            <div class="note-remove">
              <b-button variant="warning" size="sm" @click="openKillNoteConfirmation(note)"><b-icon icon="trash"/> entfernen</b-button>
            </div>
          </div>

          <div class="note-bg"></div>
        </li>
      </ul>

      <b-alert v-else variant="info" show class="text-center">
        <b-icon icon="circle-info"/> {{ $t('views.users.notes.norecords') }}
      </b-alert>
    </div>

    <div class="module">
      <div class="module-title">
        <h3>{{ $t('views.users.notes.add.title') }}</h3>
      </div>

      <note-form :entityKey="eParent.key" :entityId="eParent.id"/>
    </div>

    <b-modal
      :id="noteKillConfirmationModal.id"
      v-model="noteKillConfirmationModal.show"
      size="md"
      centered
      scrollable
      no-fade
      header-bg-variant="warning"
      header-text-variant="black"
    >
      <template #modal-header-close><b-icon icon="close"/></template>
      <template #modal-title>{{ $t('views.users.notes.modal.header.title') }}</template>

      <template v-if="noteKillConfirmationModal.show">
        <i18n path="views.users.notes.modal.body.confirmation" tag="p">
          <template #br><br></template>
          <template #message><b-badge variant="info" class="text-wrap">{{ noteKillConfirmationModal.item.text.length > 50 ? `${noteKillConfirmationModal.item.text.substr(0, 50)}...` : noteKillConfirmationModal.item.text }}</b-badge></template>
        </i18n>
      </template>

      <template #modal-footer>
        <b-button variant="light" @click="closeKillNoteConfirmation">{{ $t('views.users.notes.modal.footer.button.abort') }}</b-button>
        <b-button variant="danger" @click="killNote"><b-icon icon="trash"/> {{ $t('views.users.notes.modal.footer.button.confirm') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'
import NoteForm from '@/components/entity/NoteForm'

const ENTITY_KEY = 'usersnotes'

export default {
  name: 'Users.Notes',
  mixins: [MixinEntityBase],
  components: {
    NoteForm
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      noteKillConfirmationModal: {
        id: 'noteKillConfirmationModal',
        show: false,
        item: null
      }
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.eParent.key}/is`]
    },
    notes () {
      return [].concat(this.$store.getters[`${this.eParent.key}/getNotes`])
        .sort((a, b) => {
          return new Date(b.created).getTime() - new Date(a.created).getTime()
        })
    }
  },
  methods: {
    getEntities () {
      this.$store.dispatch(`${this.eParent.key}/getNotes`, this.eParent.id)
    },
    killNote (item) {
      this.$store.dispatch(`${this.eParent.key}/removeNote`, {
        entityId: this.eParent.id,
        note: this.noteKillConfirmationModal.item
      })
      this.closeKillNoteConfirmation()
    },
    openKillNoteConfirmation (item) {
      this.noteKillConfirmationModal.item = item
      this.noteKillConfirmationModal.show = true
    },
    closeKillNoteConfirmation () {
      this.noteKillConfirmationModal.show = false
      this.noteKillConfirmationModal.item = null
    }
  },
  created () {
    this.getEntities()
  }
}
</script>

<style lang="scss"></style>
