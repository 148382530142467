<template>
  <div>
    <h2>{{ $t('views.locations.addresses.detail.title') }}</h2>

    <entity-detail-table
      :entityKey="eParent.key"
      :entityId="eParent.id"

      @control:input="onInput"
      @control:change="onChange"
      @entity:save="onSave"
    />
  </div>
</template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'
import EntityDetailTable from '@/components/entity/DetailTable'

export default {
  name: 'Locations.Addresses.Detail',
  mixins: [MixinEntityBase],
  components: {
    EntityDetailTable
  },
  computed: {
    entity () {
      return this.$store.getters[`${this.eParent.key}/getEntity`](this.eParent.id) || null
    }
  },
  methods: {
    onApproveToggle () {
      this.$store.dispatch(`${this.eParent.key}/toogleApprove`, this.eParent.id)
    },
    onInput (controlValue) {
      /* do something with controlValue = { [propKey]: value } */
    },
    onChange (controlValue) {
      /* do something with controlValue = { [propKey]: value } */
    },
    onSave (entity) {
      this.$store.dispatch(`${this.eParent.key}/updateEntity`, entity)
    }
  }
}
</script>

<style lang="scss"></style>
