import i18n from '../core'
import { getExactLanguage, getLooseLanguage } from '../languages'
import router from '@/$plugins/router/core'
import store from '@/$plugins/store/core'

if (process.env.VUE_APP_USE_LANG_IN_URL === 'true') {
  router.beforeEach((to, from, next) => {
    router.language = router.language || to.params.lang || from.params.lang

    const routerLanguage = getExactLanguage(router.language)
    const setInitialRouteLanguage = routerLanguage === undefined
    const setRouteLanguage = !setInitialRouteLanguage && to.params.lang === undefined
    const setI18nLanguage = routerLanguage !== undefined && routerLanguage !== i18n.locale

    if (setInitialRouteLanguage) {
      // if no lang is set by url and no one is provided by router.language or router.language is not available in translations,
      // define to.params.lang from initial i18n.language
      to.params.lang = getLooseLanguage(i18n.locale)
      router.replace(to)
    } else if (setRouteLanguage) {
      // if no lang is set by url but there is one provided by router.language and is available in translations,
      // define to.params.lang from router.language
      to.params.lang = router.language
      router.replace(to)
    } else {
      next()
    }

    if (setI18nLanguage) {
      // if router.language is available but does not match i18n.locale,
      // define i18n language from to.params.lang
      store.commit('gui/setLanguage', to.params.lang)
    }
  })

  store.watch(() => store.getters['gui/getLanguage'], language => {
    // if store language changes,
    // define router.language by language
    router.language = getLooseLanguage(language)
    // update route with new lang parameter
    if (router.app.$route.name) {
      router.push({
        name: router.app.$route.name,
        params: Object.assign({}, router.app.$route.params, { lang: router.language })
      })
    }
  })
}

function getRouteLanguage (language = '') {
  return process.env.VUE_APP_USE_LANG_IN_URL === 'true' ? getLooseLanguage(language) : null
}

export {
  getRouteLanguage
}
