<template>
  <div class="user-locations">
    <h2>{{ $t('views.users.locations.title') }}</h2>

    <relation-table
      :entityKey="eCurrent.key"
      :entityIdKey="eCurrent.idKey"
      :relationKey="relationKey"

      :tableSettings="table"
    />
  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_ALL } from '@/assets/js/config/client'

import { TableHelper } from '@/assets/js/helper/table'

import MixinEntityBase from '@/components/mixins/EntityBase'
import RelationTable from '@/components/entity/RelationTable'

const ENTITY_KEY = 'locations'
const RELATION_KEY = 'userlocations'

export default {
  name: 'User.Locations',
  mixins: [MixinEntityBase],
  components: {
    RelationTable
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      relationKey: RELATION_KEY,
      entityIds: [],
      table: {
        helper: TableHelper,
        mapper: this.tableEntitiesMapper,
        search: {
          on: [],
          placeholder: this.$t('views.users.locations.relationtable.search.placeholder')
        },
        options: {
          fields: {
            includes: [],
            excludes: [],
            sorting: ['name', 'industryCode', 'customerId', 'isAccepted'],
            sortable: [CLASS_TABLE_FIELD_KEY_ALL],
            label: {},
            variant: {},
            class: {
              isAccepted: 'cell-min cell-align-center'
            },
            thClass: {},
            tdClass: {}
          }
        },
        events: {
          rowClicked: this.tableRowClicked
        }
      }
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.eCurrent.key}/is`]
    },
    definition () {
      return this.$store.getters[`${this.eCurrent.key}/definition`]
    },
    parent () {
      return this.$store.getters[`${this.eParent.key}/getEntityUnwrapped`](this.eParent.id)
    },
    entities () {
      return this.$store.getters[`${this.eCurrent.key}/getEntitiesUnwrapped`](this.entityIds)
    },
    relationsIs () {
      return this.$store.getters[`${this.relationKey}/is`]
    },
    relationsDefinition () {
      return this.$store.getters[`${this.relationKey}/definition`]
    },
    relations () {
      return this.$store.getters[`${this.relationKey}/getUnwrapped`]
    }
  },
  methods: {
    getEntities () {
      this.$store.dispatch(`${this.relationKey}/getAll`, { by: this.eParent })
    },
    tableEntitiesMapper (entities, definition) {
      return entities
        .map(e => Object.assign(e, {
          $routerLink: {
            name: 'Locations.Users',
            params: {
              [this.eCurrent.paramKey]: e.id
            }
          }
        }, e))
    },
    tableRowClicked (entity) {
      this.$router.push(entity.$routerLink)
    }
  },
  created () {
    TableHelper.rowClassHook(e => e.$routerLink ? 'has-routerlink' : null)
    this.getEntities()
  }
}
</script>

<style lang="scss">
.user-locations {
  table {

  }
}
</style>
