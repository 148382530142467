import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP } from '@/assets/js/config/server'

// import http from '@/$plugins/http'

import base from '../base'
import IS from '../base/is'
import CHANGE_REQUESTS from '../base/change-requests'
import ENTITY from '../base/entity'

export default {
  namespaced: true,
  state: base.createState(IS.state, CHANGE_REQUESTS.state, ENTITY.state, { /* define custom state-properties */ }),
  getters: base.createGetters(IS.getters, CHANGE_REQUESTS.getters, ENTITY.getters, { /* define custom getters */ }),
  mutations: base.createMutations(IS.mutations, CHANGE_REQUESTS.mutations, ENTITY.mutations, { /* define custom mutations */ }),
  actions: base.createEntityActions(STOREMODULE_ENDPOINTMODULE_MAP.customer, STOREMODULE_ENDPOINT_MAP.useraddresses, IS.actions, CHANGE_REQUESTS.actions, ENTITY.actions, { /* define custom actions */ })
}
