import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP } from '@/assets/js/config/server'

import http from '@/$plugins/http'

import base from '../base'
import IS from '../base/is'
import SEARCH from '../base/entity-search'
import MAINENTITY from '../base/entity-main'

export default {
  namespaced: true,
  state: base.createState(IS.state, SEARCH.state, MAINENTITY.state, { /* define custom state-properties */ }),
  getters: base.createGetters(IS.getters, SEARCH.getters, MAINENTITY.getters, { /* define custom getters */ }),
  mutations: base.createMutations(IS.mutations, SEARCH.mutations, MAINENTITY.mutations, { /* define custom mutations */ }),
  actions: base.createEntityActions(STOREMODULE_ENDPOINTMODULE_MAP.catalog, STOREMODULE_ENDPOINT_MAP.sellingpoints, IS.actions, SEARCH.actions, MAINENTITY.actions, {
    uploadCsvFile (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = {}) {
        return new Promise(resolve => {
          http({
            method: 'post',
            url: `/${serverModuleName}/api/${serverEntityName}/import`,
            data: options.data

          })
            .then(response => {
              dispatch('searchByFilters')
              resolve(response)
            })
        })
      }
    },
    downloadCsvFile (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = {}) {
        return new Promise(resolve => {
          http({
            method: 'get',
            url: `/${serverModuleName}/api/${serverEntityName}/export`,
            responseType: 'blob'
          })
            .then(response => {
              resolve(response)
            })
        })
      }
    }
  })
}
