import { SEARCH_FILTER_COMPONENT_TYPES } from '@/assets/js/config/server'
import { SEARCH_INITIAL_VALUES, SEARCH_FILTER_EMPTY_CONTROL_VALUES, SEARCH_FILTER_URL_PARAMETER_SEPARATOR } from '@/assets/js/config/client'

import { getSearchParameters } from '@/assets/js/helper/url'

import i18n from '@/$plugins/i18n/core'

const SEARCH = {
  isInitialized: false,
  initialParameters: [],
  urlParameterKeys: []
}

export function filterIdGenerator (filter = {}) {
  return `${window.location.pathname}:${filter.field}`
}

export function filterControlIdGenerator (filter = {}, control = {}) {
  return `${filter.field}_${control.key}`
}

export function sortIdGenerator (sort = {}) {
  return `${window.location.pathname}:${sort.field}_${sort.isDescending ? 'descending' : 'ascending'}`
}

export function filterMapper (filter = {}, existingFilter = {}) {
  const COMPONENT_VALUE = (Object.values(SEARCH_FILTER_COMPONENT_TYPES).find(t => t.type === filter.type) || {})
  const COMPONENT = COMPONENT_VALUE.component || COMPONENT_VALUE.type || SEARCH_FILTER_COMPONENT_TYPES.unknown.type
  const INITIAL_PARAMETER_VALUES = (SEARCH.initialParameters.find(p => p.key === filter.key) || {}).values || []

  return {
    id: filterIdGenerator(filter),
    field: filter.field,
    key: filter.key,
    type: filter.type,
    component: `filter-${COMPONENT}`,
    sortOrder: filter.sortOrder,
    displayName: (filter.displayName || {})[i18n.locale],
    controls: getControls()
  }

  function getControls () {
    let controls = [{ unknown: `The type '${filter.type}' of filter '${filter.field}' is unknown! You may want to define mapping logic for this type of filters in '@/assets/js/helper/search.js'.` }]

    if (filter.type === SEARCH_FILTER_COMPONENT_TYPES.text.type) {
      const EXISTING_CONTROL = (existingFilter.controls || [])[0] || {}
      const CONTROL_VALUE = INITIAL_PARAMETER_VALUES[0] || SEARCH_FILTER_EMPTY_CONTROL_VALUES[filter.type]

      controls = [Object.assign({
        id: filterControlIdGenerator(filter),
        value: CONTROL_VALUE
      }, EXISTING_CONTROL)]
    } else if (filter.type === SEARCH_FILTER_COMPONENT_TYPES.multiselect.type) {
      controls = filter.items
        .map(item => {
          const CONTROL_ID = filterControlIdGenerator(filter, item)
          const EXISTING_CONTROL = (existingFilter.controls || []).find(c => c.id === CONTROL_ID) || {}
          const CONTROL_VALUE = INITIAL_PARAMETER_VALUES.includes(item.key) || SEARCH_FILTER_EMPTY_CONTROL_VALUES[filter.type]

          return Object.assign({
            id: CONTROL_ID,
            key: item.key,
            text: item.key,
            value: CONTROL_VALUE,
            hide: false
          }, EXISTING_CONTROL, { count: item.count })
        })
    } else if (filter.type === SEARCH_FILTER_COMPONENT_TYPES.daterange.type) {
      controls = Object.values(SEARCH_FILTER_COMPONENT_TYPES.daterange.controlKeys)
        .map((dKey, dIndex) => {
          const CONTROL_ID = filterControlIdGenerator(filter, { key: dKey })
          const EXISTING_CONTROL = (existingFilter.controls || []).find(c => c.id === CONTROL_ID) || {}
          const CONTROL_VALUE = INITIAL_PARAMETER_VALUES[dIndex] || SEARCH_FILTER_EMPTY_CONTROL_VALUES[filter.type]

          return Object.assign({
            id: CONTROL_ID,
            key: dKey,
            value: CONTROL_VALUE
          }, EXISTING_CONTROL)
        })
    } else if (filter.type === SEARCH_FILTER_COMPONENT_TYPES.geopoint.type) {
      controls = []
    }

    controls.forEach(c => { if (c.unknown) console.warn(c.unknown) })

    return controls
  }
}

export function sortMapper (sort = {}, existingSort = {}) {
  return Object.assign({
    id: sortIdGenerator(sort),
    field: sort.field,
    sortOrder: sort.sortOrder,
    isDefault: sort.isDefault,
    isDescending: sort.isDescending,
    displayName: (sort.displayName || {})[i18n.locale],
    isActive: sort.isDefault
  }, existingSort)
}

export function filterControlResetter (filter = {}) {
  return filter.controls
    .map(control => {
      if (filter.type === SEARCH_FILTER_COMPONENT_TYPES.multiselect.type) control.value = SEARCH_FILTER_EMPTY_CONTROL_VALUES[filter.type]
      else control.value = SEARCH_FILTER_EMPTY_CONTROL_VALUES[filter.type]

      return control
    })
}

export function searchParameters ({ filters = [], sorts = [], statistics = {} }) {
  return {
    filters: filterParameters(filters),
    sorts: sortParameters(sorts),
    page: pageParameters(statistics)
  }
}

export function filterParameters (filters = []) {
  const filterParameterBase = !SEARCH.isInitialized ? initialParameterGetter() : filters
  SEARCH.isInitialized = true

  return filterParameterBase
    .map(filter => {
      return {
        key: filter.key,
        field: filter.field,
        values: getFilterValues(filter)
      }
    })
    .filter(filter => filter.values.length > 0)

  function getFilterValues (filter = {}) {
    // this is for allready mapped filters
    if (filter.controls) {
      return filter.controls
        .filter(c => {
          if (filter.type === SEARCH_FILTER_COMPONENT_TYPES.daterange.type) return filter.controls.some(fc => fc.value)
          return c.value !== SEARCH_FILTER_EMPTY_CONTROL_VALUES[filter.type]
        })
        .map(c => {
          if (filter.type === SEARCH_FILTER_COMPONENT_TYPES.multiselect.type) return c.key
          return c.value
        })
        .flat()

      // this is for initial filterParameters
    } else if (filter.values) {
      return filter.values.map(v => v || null)
    } else {
      return []
    }
  }
}

export function sortParameters (sorts = []) {
  return sorts.length <= 0 ? null : sorts
    .filter(sort => sort.isActive)
    .map(sort => ({
      field: sort.field,
      descending: sort.isDescending
    }))
}

export function pageParameters (statistics = {}) {
  const pageStats = statistics.page || {}

  return {
    number: pageStats.number || SEARCH_INITIAL_VALUES.page,
    size: pageStats.size || SEARCH_INITIAL_VALUES.pageSize
  }
}

export function initialParameterGetter () {
  return SEARCH.initialParameters
}

export function initialParameterSetter (initialParameters = []) {
  const initialUrlParameters = getBaseUrlParameters()

  const PARAMETER_KEYS = Array.from(new Set([].concat(initialUrlParameters, initialParameters).map(p => p.key)))
  const INITIAL_PARAMETERS = PARAMETER_KEYS.map(pKey => initialUrlParameters.find(p => p.key === pKey) || initialParameters.find(p => p.key === pKey))

  urlParameterKeySetter(INITIAL_PARAMETERS)

  SEARCH.initialParameters = INITIAL_PARAMETERS
    .map(f => ({
      key: f.key,
      values: f.values || []
    }))
}

export function urlParameterGetter () {
  return getBaseUrlParameters()
    .filter(p => SEARCH.urlParameterKeys.includes(p.key))
}

export function urlParameterKeySetter (filterParameters = []) {
  SEARCH.urlParameterKeys = filterParameters
    .map(f => f.key)
}

export function urlParameterSetter (filterParameters = []) {
  const URL_PARAMETERS = getBaseUrlParameters()
    .filter(p => !SEARCH.urlParameterKeys.includes(p.key))
    .concat(filterParameters)
    .reduce((url, p) => `${url}&${p.key}=${p.values.join(SEARCH_FILTER_URL_PARAMETER_SEPARATOR)}`, '')
    .replace(/^&/, '?')

  window.history.replaceState(null, null, encodeURI(URL_PARAMETERS) || window.location.pathname)
}

export function fieldToKeyConverter (field = '') {
  return field.replace(/(-[a-z]{2})?\..*$/, '')
}

function getBaseUrlParameters () {
  return getSearchParameters()
    .map(p => ({
      key: p.key,
      values: p.value.split(SEARCH_FILTER_URL_PARAMETER_SEPARATOR)
    }))
}

export default {
  filterIdGenerator,
  filterControlIdGenerator,
  sortIdGenerator,
  filterMapper,
  sortMapper,
  filterControlResetter,
  searchParameters,
  filterParameters,
  sortParameters,
  pageParameters,
  initialParameterGetter,
  initialParameterSetter,
  urlParameterKeySetter,
  urlParameterGetter,
  urlParameterSetter,
  fieldToKeyConverter
}
