<template>
  <div>
    <template v-if="isEntryRoute || isDetailRouteWithoutParameter">
      <entity-entry-header :entityKey="eCurrent.key" :title="$t(`views.${eCurrent.key}.title`)"/>
    </template>
    <loader v-if="isLoading"/>

    <!-- if this is active route (e.g. /sellingpoints) -->
    <template v-if="isEntryRoute">
      <b-row class="mb-3" align-h="between">
        <b-col cols="auto"></b-col>
        <b-col cols="auto">
          <b-button variant="outline-primary" @click="openFileExplorer">{{ $t('views.sellingpoints.button.importsellingpoint') }}</b-button>
          <b-button class="ml-1" variant="outline-primary" @click="exportSellingPoints">{{ $t('views.sellingpoints.button.exportsellingpoint') }}</b-button>
          <b-button class="ml-1" variant="outline-primary" @click="navigate('SellingPoints.Add')"><b-icon icon="plus"/> {{ $t('views.sellingpoints.button.addsellingpoint') }}</b-button>
        </b-col>
      </b-row>
      <input type="file" ref="fileInput" @change="fileUpload" accept=".csv" style="display: none;" />

      <entity-entry-table :entityKey="eCurrent.key" :tableSettings="table" searchable>
        <template #cell(requiredactions)="data">
          <div class="badge-group">
            <b-badge v-for="action in data.cell.item.requiredactions" :key="action" variant="warning">{{ action }}</b-badge>
          </div>
        </template>
      </entity-entry-table>
    </template>

    <!-- if subroute is active route (e.g. /sellingpoints/detail/:sellingpointid) -->
    <section v-else-if="isDetailRouteWithParameter">
      <entity-detail-header :entityKey="eCurrent.key" :entityId="eCurrent.id">
        <template #default="data">
          <dl class="detailheader-list">
            <dt class="sr-only">{{ $t('components.entity.detailheader.name.label') }}</dt>
            <dd><h1>{{ data.label }}</h1></dd>

            <div class="w-100"></div>
            <dt>{{ data.definition.$properties.email.translations[$i18n.locale].name }}</dt>
            <dd>{{ data.entity.email }}</dd>
          </dl>
        </template>
      </entity-detail-header>

      <router-view/>
    </section>

    <!-- if subroute is active route (e.g. /sellingpoints/add) -->
    <router-view v-else-if="isDetailRouteWithoutParameter"/>
  </div>
</template>

<script>
import Loader from '@/components/Loader'

import { CLASS_TABLE_FIELD_KEY_UNKNOWN } from '@/assets/js/config/client'

import { TableHelper } from '@/assets/js/helper/table'

import MixinEntityBase from '@/components/mixins/EntityBase'
import EntityEntryHeader from '@/components/entity/EntryHeader'
import EntityEntryTable from '@/components/entity/EntryTable'
import EntityDetailHeader from '@/components/entity/DetailHeader'

const ENTITY_KEY = 'sellingpoints'

export default {
  name: 'SellingPoints',
  mixins: [MixinEntityBase],
  components: {
    EntityEntryHeader,
    EntityEntryTable,
    EntityDetailHeader,
    Loader
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      isLoading: false,
      table: {
        helper: TableHelper,
        mapper: this.tableEntitiesMapper,
        options: {
          fields: {
            sorting: [CLASS_TABLE_FIELD_KEY_UNKNOWN, 'requiredactions'],
            labelKey: `views.${this.$options.name.toLowerCase()}.table.label.{field}`,
            class: {
              requiredactions: 'cell-align-right'
            },
            thClass: {
              requiredactions: 'cell-min cell-nowrap'
            }
          }
        },
        events: {
          rowClicked: this.tableRowClicked
        }
      }
    }
  },
  computed: {
    isEntryRoute () {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find(c => c.name === this.$options.name) !== undefined
    },
    isDetailRouteWithParameter () {
      return this.$route.matched[this.$route.matched.length - 1].path.indexOf(`/:${this.eCurrent.paramKey}`) >= 0
    },
    isDetailRouteWithoutParameter () {
      return !this.isEntryRoute && !this.isDetailRouteWithParameter
    }
  },
  methods: {
    tableEntitiesMapper (entities, definition) {
      return entities
        .map(e => Object.assign({}, e, {
          $routerLink: {
            name: `${this.$options.name}.Detail`,
            params: {
              [this.eCurrent.paramKey]: e.id
            }
          }
        }))
    },
    tableRowClicked (entity) {
      this.$router.push(entity.$routerLink)
    },
    exportSellingPoints () {
      this.$store.dispatch(`${this.eCurrent.key}/downloadCsvFile`)
        .then(response => {
          if (!response || !response.data) {
            console.error('No data received for download')
            return
          }
          const contentDisposition = response.headers['content-disposition']
          let filename = 'Export.csv'
          if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename\*?="?([^;"]+)/)
            if (filenameMatch.length > 1) {
              filename = filenameMatch[1]
            }
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        })
        .catch(error => {
          console.error('Error during export:', error)
        })
    },
    openFileExplorer () {
      this.$refs.fileInput.click()
    },
    async fileUpload (event) {
      this.isLoading = true
      const file = event.target.files[0]
      const fileName = file.name
      const base64File = await this.getBase64(file)
      const data = {
        data: {
          base64File: base64File.split(',')[1],
          fileName: fileName
        }
      }

      this.$store.dispatch(`${this.eCurrent.key}/uploadCsvFile`, data)
        .then(() => {
          this.isLoading = false
        })
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    }
  },
  created () {
    TableHelper.rowClassHook(entity => entity.$routerLink ? 'has-routerlink' : null)
  }
}
</script>

<style lang="scss"></style>
