import Vue from 'vue'
import App from '@/App.vue'

import router from '@/$plugins/router'
import store from '@/$plugins/store'
import http from '@/$plugins/http'
import auth, { authManagers } from '@/$plugins/auth'
import i18n from '@/$plugins/i18n'
import timeago from '@/$plugins/timeago'
import bootstrap from '@/$plugins/bootstrap'
import vuelidate from '@/$plugins/vuelidate'
import moment from 'moment'

import '@/components/global'

// vue settings
Vue.config.productionTip = false

Vue.filter('formatDate', value => {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY')
  }
})

Vue.filter('formatDateTime', value => {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY hh:mm')
  }
})

authManagers.autologin()
  .then(() => {
    // global data fetches
    store.dispatch('validators/get')

    const $app = new Vue({
      router,
      store,
      http,
      auth,
      i18n,
      timeago,
      bootstrap,
      vuelidate,
      render: h => h(App)
    }).$mount('#app')

    Vue.prototype.$app = Vue.$app = Object.assign($app, Vue.$app)
  })
