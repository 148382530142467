<template>
  <div>
    <h2>{{ $t('views.sellingpoints.changetracking.title') }}</h2>

    <entity-changetracking-table :entityBy="eParent" :storeModule="storeModule"/>
  </div>
</template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'
import EntityChangetrackingTable from '@/components/entity/ChangetrackingTable'
import { STOREMODULE_ENDPOINTMODULE_MAP } from '@/assets/js/config/server'

export default {
  name: 'SellingPoints.ChangeTracking',
  mixins: [MixinEntityBase],
  components: {
    EntityChangetrackingTable
  },
  computed: {
    storeModule () {
      return STOREMODULE_ENDPOINTMODULE_MAP.catalog
    }
  }
}
</script>

<style lang="scss"></style>
