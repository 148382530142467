import { REQUIRED_ACTIONS_INITIAL_VALUES } from '@/assets/js/config/client'
import { STOREMODULE_ENDPOINTMODULE_MAP } from '@/assets/js/config/server'

import Vue from 'vue'
import http from '@/$plugins/http'

import base from './index'

export default {
  state: {
    requiredActions: []
  },
  getters: {
    getRequiredActions: state => state.requiredActions,
    getRequiredAction: state => actionId => state.requiredActions.find(a => a.id === actionId)
  },
  mutations: {
    setRequiredActions (state, actions = []) {
      state.requiredActions = actions
    },
    setRequiredAction (state, action = {}) {
      const updateIndex = state.requiredActions.findIndex(a => a.id === action.id)
      const newIndex = state.requiredActions.length

      Vue.set(state.requiredActions, updateIndex >= 0 ? updateIndex : newIndex, action)
    }
  },
  actions: {
    getRequiredActions (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch, rootGetters }, options = {}) {
        const o = base.getOptions(options, {
          language: rootGetters['gui/getLanguage'],
          page: REQUIRED_ACTIONS_INITIAL_VALUES.page,
          pageSize: REQUIRED_ACTIONS_INITIAL_VALUES.pageSize
        })

        commit('setLoading', { key: 'getRequiredActions', loading: true, initial: true })

        return new Promise(resolve => {
          http({
            method: 'post',
            url: `/${STOREMODULE_ENDPOINTMODULE_MAP.search}/api/${serverEntityName}/RequiredActions`,
            data: o.data
          })
            .then(response => {
              commit('setRequiredActions', response.data.result)
              resolve(getters.getRequiredActions)
            })
            .finally(() => {
              commit('setLoading', { key: 'getRequiredActions', loading: false })
            })
        })
      }
    }
  }
}
