<template>
  <section>
    {{ $options.name }}
    <p>TODO: setup view</p>

    <router-link to="/">Home</router-link>

    <b-btn @click="logout">logout</b-btn>
  </section>
</template>

<script>
import { authManagers } from '@/$plugins/auth'

export default {
  name: 'Forbidden',
  methods: {
    logout () {
      authManagers.logout()
    }
  }
}
</script>

<style lang="scss">

</style>
