<template>
  <div class="dev">
    <h1>Vue Environment</h1>

    <div class="module">
      <div class="module-title">Plugins</div>

      <div class="module-infos">
        <div>
          <div class="info-title">Router</div>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Version</b-col>
            <b-col class="row-value">{{ $app.router.version }}</b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Vue Instance</b-col>
            <b-col class="row-value">this.$router</b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Options</b-col>
            <b-col class="row-value">
              <template v-for="(option, oKey) in getPluginOptions($app.router.options, ['routes'])">
                <b-form-row class="info-row" :key="oKey">
                  <b-col class="row-label">{{ oKey }}</b-col>
                  <b-col class="row-value"><pre class="text-xs">{{ option }}</pre></b-col>
                </b-form-row>
              </template>
            </b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Docs</b-col>
            <b-col class="row-value"><b-link href="https://router.vuejs.org/" target="_blank">https://router.vuejs.org/</b-link></b-col>
          </b-form-row>
        </div>

        <div>
          <div class="info-title">Store</div>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Version</b-col>
            <b-col class="row-value">{{ $app.store.version }}</b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Vue Instance</b-col>
            <b-col class="row-value">this.$store</b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Modules</b-col>
            <b-col class="row-value">
              <template v-for="(option, oKey) in getPluginOptions($app.store.state)">
                <b-form-row class="info-row" :key="oKey">
                  <b-col class="row-label">{{ oKey }}</b-col>
                  <!-- <b-col class="row-value"><pre class="text-xs">{{ option }}</pre></b-col> -->
                </b-form-row>
              </template>
            </b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Docs</b-col>
            <b-col class="row-value"><b-link href="https://vuex.vuejs.org/" target="_blank">https://vuex.vuejs.org/</b-link></b-col>
          </b-form-row>
        </div>

        <div v-for="(httpInstance, httpKey) in $app.http.instances" :key="httpKey">
          <div class="info-title">Http ({{ httpKey }})</div>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Version</b-col>
            <b-col class="row-value">{{ $app.store.version }}</b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Vue Instance</b-col>
            <b-col class="row-value">this.{{ httpInstance.defaults.name }}</b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Options</b-col>
            <b-col class="row-value">
              <template v-for="(option, oKey) in getPluginOptions(httpInstance.defaults, ['name', 'transformRequest', 'transformResponse', 'adapter', 'validateStatus'])">
                <b-form-row class="info-row" :key="oKey">
                  <b-col class="row-label">{{ oKey }}</b-col>
                  <b-col class="row-value"><pre class="text-xs">{{ option }}</pre></b-col>
                </b-form-row>
              </template>
            </b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Docs</b-col>
            <b-col class="row-value"><b-link href="https://github.com/axios/axios" target="_blank">https://github.com/axios/axios</b-link></b-col>
          </b-form-row>
        </div>

        <div>
          <div class="info-title">Auth</div>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Version</b-col>
            <b-col class="row-value">{{ $app.auth.version }}</b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Vue Instance</b-col>
            <b-col class="row-value">this.$auth</b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Options</b-col>
            <b-col class="row-value">
              <template v-for="(option, oKey) in getPluginOptions($app.auth.options, ['getUrl', 'getCookieDomain', 'parseUserData'])">
                <b-form-row class="info-row" :key="oKey">
                  <b-col class="row-label">{{ oKey }}</b-col>
                  <b-col class="row-value"><pre class="text-xs">{{ option }}</pre></b-col>
                </b-form-row>
              </template>
            </b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Docs</b-col>
            <b-col class="row-value"><b-link href="https://websanova.com/docs/vue-auth/" target="_blank">https://websanova.com/docs/vue-auth/</b-link></b-col>
          </b-form-row>
        </div>

        <div>
          <div class="info-title">I18n</div>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Version</b-col>
            <b-col class="row-value">{{ $app.i18n.version }}</b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Vue Instance</b-col>
            <b-col class="row-value">this.$i18n</b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Options</b-col>
            <b-col class="row-value">
              <template v-for="(option, oKey) in getPluginOptions($app.i18n, ['*'], ['locale', 'fallbackLocale', 'availableLocales'])">
                <b-form-row class="info-row" :key="oKey">
                  <b-col class="row-label">{{ oKey }}</b-col>
                  <b-col class="row-value"><pre class="text-xs">{{ option }}</pre></b-col>
                </b-form-row>
              </template>
            </b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Docs</b-col>
            <b-col class="row-value"><b-link href="https://kazupon.github.io/vue-i18n/" target="_blank">https://kazupon.github.io/vue-i18n/</b-link></b-col>
          </b-form-row>
        </div>

        <div>
          <div class="info-title">Timeago</div>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Version</b-col>
            <b-col class="row-value">{{ $app.timeago.version }}</b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Vue Instance</b-col>
            <b-col class="row-value">this.$timeago</b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Options</b-col>
            <b-col class="row-value">
              <template v-for="(option, oKey) in getPluginOptions($app.timeago)">
                <b-form-row class="info-row" :key="oKey">
                  <b-col class="row-label">{{ oKey }}</b-col>
                  <b-col class="row-value"><pre class="text-xs">{{ option }}</pre></b-col>
                </b-form-row>
              </template>
            </b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Docs</b-col>
            <b-col class="row-value"><b-link href="https://github.com/egoist/vue-timeago" target="_blank">https://github.com/egoist/vue-timeago</b-link></b-col>
          </b-form-row>
        </div>

        <div>
          <div class="info-title">Bootstrap</div>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Version</b-col>
            <b-col class="row-value">{{ $app.bootstrap.version }}</b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Docs</b-col>
            <b-col class="row-value"><b-link href="https://bootstrap-vue.org/docs" target="_blank">https://bootstrap-vue.org/docs</b-link></b-col>
          </b-form-row>
        </div>

        <div>
          <div class="info-title">BootstrapIcons</div>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Version</b-col>
            <b-col class="row-value">{{ $app.bootstrapIcons.version }}</b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Docs</b-col>
            <b-col class="row-value"><b-link href="https://bootstrap-vue.org/docs/icons" target="_blank">https://bootstrap-vue.org/docs/icons</b-link></b-col>
          </b-form-row>
        </div>

        <div>
          <div class="info-title">Vuelidate</div>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Version</b-col>
            <b-col class="row-value">{{ $app.vuelidate.version }}</b-col>
          </b-form-row>

          <b-form-row class="info-row">
            <b-col md="2" class="row-label">Docs</b-col>
            <b-col class="row-value"><b-link href="https://vuelidate.js.org/" target="_blank">https://vuelidate.js.org/</b-link></b-col>
          </b-form-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DevVue',
  methods: {
    getPluginOptions (options = {}, excludeKeys = [], musthaveKeys = []) {
      const keyBase = musthaveKeys.reduce((keys, k) => Object.assign(keys, { [k]: undefined }), {})

      return Object.keys(Object.assign(keyBase, options))
        .reduce((o, oKey) => (!excludeKeys.includes('*') && !excludeKeys.includes(oKey)) || musthaveKeys.includes(oKey) ? Object.assign(o, { [oKey]: options[oKey] }) : o, {})
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/modules/dev/view.scss';
</style>
