import Vue from 'vue'
import router from '@/$plugins/router/core'
import { httpAuth as http } from '@/$plugins/http/core'

import VueAuth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js'
import driverAuth from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js'
import driverHttp from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js'
import driverRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js'

Vue.use(VueAuth, {
  plugins: {
    router,
    http
  },
  drivers: {
    router: driverRouter,
    http: driverHttp,
    auth: driverAuth
  },
  options: {
    rolesKey: 'roles',
    rememberKey: 'AUTH_REMEMBER',
    staySignedInKey: 'AUTH_STAYSIGNEDIN',
    tokenDefaultKey: 'AUTH_TOKEN',
    tokenRefreshKey: 'REFRESH_TOKEN',
    stores: ['storage', 'cookie'],
    cookie: {
      path: router.options.base,
      domain: null,
      secure: true,
      expores: Infinity,
      SameSite: 'none'
    },
    authRedirect: { name: 'Login', path: undefined },
    forbiddenRedirect: { name: 'Forbidden', path: undefined },
    notFoundRedirect: { name: 'Notfound', path: undefined },
    loginData: {
      url: '/api/Auth',
      method: 'get',
      redirect: router.options.base,
      fetchUser: false,
      staySignedIn: false
    },
    fetchData: {
      enabled: false,
      url: '/api/Auth/user',
      method: 'get'
    },
    refreshData: {
      enabled: false,
      url: '/api/Auth/refresh',
      method: 'post',
      interval: 10
    },
    logoutData: {
      makeRequest: false,
      url: '/authenticate/logout',
      method: 'post',
      redirect: { name: 'Logout' }
    }
  }
})

export default Vue.auth
