<template>
  <div id="Layout" data-layout-type="main">
    <layout-header class="layout-row row-static"/>

    <div class="layout-row row-shrink">
      <layout-navigation class="layout-col col-static"/>

      <main class="position-relative layout-col col-shrink layout-content layout-scroll">
        <slot/>
      </main>
    </div>

    <layout-footer class="layout-row row-static"/>
  </div>
</template>

<script>
import LayoutHeader from '@/components/Header'
import LayoutNavigation from '@/components/Navigation'
import LayoutFooter from '@/components/Footer'

export default {
  name: 'LayoutMain',
  components: {
    LayoutHeader,
    LayoutNavigation,
    LayoutFooter
  }
}
</script>

<style lang="scss">
@import '@/assets/css/modules/layout.scss';
</style>
