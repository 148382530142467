export const TOASTER_OPTIONS = {
  api: {
    id: 'ApiToasts',
    options: {
      error: {
        toaster: 'ApiToasts',
        variant: 'danger',
        title: 'API Error',
        noCloseButton: true,
        isStatus: true,
        appendToast: true,
        autoHideDelay: 4000
      },
      send: {
        toaster: 'ApiToasts',
        variant: 'success',
        noCloseButton: true,
        isStatus: true,
        appendToast: true,
        autoHideDelay: 1500
      }
    }
  }
}

export const CLASS_TABLE_GLOBAL_FIELD_EXCLUDES = [
  'id',
  '_hasDetails',
  '_isDetails',
  '_showDetails',
  '_showDetailsHook',
  '_isHidden',
  '_rowVariant'
]

export const CLASS_TABLE_FIELD_KEY_ALL = '~all~'

export const CLASS_TABLE_FIELD_KEY_UNKNOWN = '~unknown~'

export const CLASS_TABLE_FIELD_KEYS_UNSORTABLE = [
  '$toggleDetails',
  '$kill'
]

export const ENTITY_STATEBADGE_VARIANTS = {
  new: 'new',
  ok: 'light',
  openChangeRequests: 'warning', // registerd, but untouched (show Buttons)
  pendingChangeRequests: 'warning' // accepted, but not DIAS confirmed (do not show buttons)
}

export const CHANGETRACKING_STATEBADGE_VARIANTS = {
  created: 'new',
  updated: 'change',
  deleted: 'delete'
}

export const ENTITY_CONTROL_HIDDEN_BY_PROPERTY_KEYS = [
  'hidden'
]

export const PAGINATION_INITIAL_VALUES = {
  page: 1,
  pageSize: 50
}

export const REQUIRED_ACTIONS_INITIAL_VALUES = {
  page: 1,
  pageSize: 10
}

export const SEARCH_INITIAL_VALUES = {
  page: 1,
  pageSize: 30
}

export const SEARCH_FILTER_EXPANDED_DEFAULT = true

export const SEARCH_FILTER_EMPTY_CONTROL_VALUES = {
  text: '',
  multiselect: false,
  daterange: null
}

export const SEARCH_FILTER_URL_PARAMETER_SEPARATOR = '++'

export const ORDERED_ENTITYPROPERTY_ROWGROUPS = [
  // {
  //   zip: '4',
  //   city: '8'
  // }
]

export const ORDER_ORDERSTATE_COLORCODES = {
  Ordered: 'success',
  Processing: 'warning'
}
