<script>
export default {
  data () {
    return {
      searchExpanded: false,
      search: {
        id: `${this.filter.field}_Search`,
        value: '',
        placeholder: this.$t('components.navigation.filters.search.placeholder')
      }
    }
  },
  methods: {
    toggleSearchExpanded (state = null) {
      this.searchExpanded = state !== null ? state : !this.searchExpanded

      this.$nextTick(() => {
        if (this.searchExpanded) {
          this.$refs.searchControl.$el.querySelector('input').focus()
        }
      })
    },
    searchItems (value) {
      const lowerValue = value.toLowerCase()

      this.filter.controls
        .forEach(c => {
          c.hide = !c.text.toLowerCase().includes(lowerValue)
        })
    },
    resetSearch () {
      this.search.value = ''
      this.searchItems(this.search.value)
    }
  }
}
</script>

<style lang="scss">
$searchfilter-search-expander-color: $primary !default;

$searchfilter-search-control-margin: 0 0 ($spacer * 0.5) !default;
$searchfilter-search-control-padding: 0 !default;

$searchfilter-search-control-input-padding-x: $input-padding-x-sm !default;
$searchfilter-search-control-input-bg: $white !default;

$searchfilter-search-control-reset-color: $danger !default;
$searchfilter-search-control-reset-color-disabled: $btn-link-disabled-color !default;

.search-filter {
  .filter-search-expander {
    padding-right: 0;
    color: $searchfilter-search-expander-color;
    box-shadow: none;
  }

  .filter-search-control {
    position: relative;
    margin: $searchfilter-search-control-margin;
    padding: $searchfilter-search-control-padding;
    width: 100%;

    .control-input {
      position: relative;
      margin: 0;
      z-index: 1;

      input {
        padding-left: $searchfilter-search-control-input-padding-x;
        padding-right: $searchfilter-search-control-input-padding-x;
        background-color: $searchfilter-search-control-input-bg;
        border: 0 none;
      }
    }

    .search-control-reset {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: $searchfilter-search-control-input-padding-x;
      padding: 0;
      width: 1.5em;
      color: $searchfilter-search-control-reset-color;
      box-shadow: none;
      z-index: 2;

      &.disabled {
        color: $searchfilter-search-control-reset-color-disabled;
      }
    }
  }
}
</style>
