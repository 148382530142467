<script>
import { ROUTENAME_REQUIREDACTION_MAP } from '@/assets/js/config/server'

import string from '@/assets/js/helper/string'

const REQUIREDACTION_ROUTENAME_MAP = Object.values(ROUTENAME_REQUIREDACTION_MAP).reduce((maps, map) => Object.assign(maps, { [map.requiredActionKey]: map.routeName }), {})

export default {
  computed: {
    eParent () {
      return (this.$parent || {}).eCurrent || {}
    },
    eCurrent () {
      const CURRENT_KEY = this.entityKey || ''
      const CURRENT_NAME = string.singularize(CURRENT_KEY)
      const CURRENT_ID_KEY = `${CURRENT_NAME}Id`
      const CURRENT_ID_KEY_LOWER = CURRENT_ID_KEY.toLowerCase()

      return {
        key: CURRENT_KEY,
        name: CURRENT_NAME,
        idKey: CURRENT_ID_KEY,
        paramKey: CURRENT_ID_KEY_LOWER,
        id: this.$route.params[CURRENT_ID_KEY_LOWER]
      }
    },
    ePath () {
      return [this.eCurrent].concat(this.$parent.ePath || [])
    },
    entityParent () {
      return this.eParent.id ? this.$store.getters[`${this.eParent.key}/getEntity`](this.eParent.id) || {} : {}
    },
    entityCurrent () {
      return this.eCurrent.id ? this.$store.getters[`${this.eCurrent.key}/getEntity`](this.eCurrent.id) || {} : {}
    }
  },
  methods: {
    getEntity () {
      if (this.entityCurrent.id === undefined && this.eCurrent.id) this.$store.dispatch(`${this.eCurrent.key}/getEntity`, this.eCurrent.id)
    },
    navigate (routeName = this.$route.name, options = {}) {
      if (routeName !== this.$route.name) this.$router.push(Object.assign({ name: routeName }, options))
    },
    notifyNavigationLinksWithUpdates () {
      ((this.entityCurrent.value || {}).relatedEntitiesWithRequiredActions || [])
        .forEach(actionKey => {
          if (REQUIREDACTION_ROUTENAME_MAP[actionKey]) this.$root.$emit(`route:${REQUIREDACTION_ROUTENAME_MAP[actionKey]}:hasUpdates`)
        })
    }
  },
  created () {
    this.getEntity()
  },
  watch: {
    eCurrent () {
      this.getEntity()
    },
    'entityCurrent.value.relatedEntitiesWithRequiredActions' () {
      this.notifyNavigationLinksWithUpdates()
    }
  }
}
</script>
