<template>
  <div :class="['search-filter', 'search-filter-daterange', { 'is-expanded': expanded }]">
    <div class="filter-header">
      <b-button class="filter-expander" variant="link" block @click="toggleExpanded()">
        <b-icon icon="arrow-dropdown"/>{{ filter.displayName }} <span v-if="filterSetCount" class="filter-set-count">{{ filterSetCount }}</span>
      </b-button>

      <b-button v-if="expanded" class="filter-search-expander" variant="link" @click="toggleSearchExpanded()"><b-icon icon="search"/></b-button>
    </div>

    <transition-expand>
      <div v-if="expanded" class="filter-body">
        <div class="filter-controls">
          <div class="controls-meta">
            <b-form-row class="justify-content-between align-items-center">
              <b-col cols="auto"></b-col>

              <b-col cols="auto">
                <b-button variant="link" :disabled="noneFilterSet" @click="resetControls">{{ $t('components.navigation.filters.button.selectnone') }}</b-button>
              </b-col>
            </b-form-row>
          </div>

          <div
            v-for="control in filter.controls"
            :key="control.id"
            v-show="!control.hide"
            class="controls-control"
          >
            <control-datepicker
              :id="control.id"
              :value="control.value"
              :dateDisabledFn="($ymd, $date) => dateIsDisabled(control.key, $ymd, $date)"
              :resetValue="resetValue"
              size="sm"
              @input="$value => updateDatepicker(control, $value)"
            />

            <div class="control-count">{{ control.count }}</div>
          </div>
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import { SEARCH_FILTER_COMPONENT_TYPES } from '@/assets/js/config/server'
import { SEARCH_FILTER_EMPTY_CONTROL_VALUES } from '@/assets/js/config/client'

import MixinFilterBase from '@/components/search/MixinFilterBase'
import ControlDatepicker from '@/components/form/ControlDatepicker'
import TransitionExpand from '@/components/extension/TransitionExpand'

export default {
  name: 'Search.FilterDaterange',
  mixins: [MixinFilterBase],
  components: {
    ControlDatepicker,
    TransitionExpand
  },
  data () {
    return {
      resetValue: SEARCH_FILTER_EMPTY_CONTROL_VALUES.daterange
    }
  },
  computed: {
    dateFrom () {
      return (this.filter.controls.find(c => c.key === SEARCH_FILTER_COMPONENT_TYPES.daterange.controlKeys.from) || {}).value
    },
    dateTill () {
      return (this.filter.controls.find(c => c.key === SEARCH_FILTER_COMPONENT_TYPES.daterange.controlKeys.till) || {}).value
    }
  },
  methods: {
    dateIsDisabled (controlKey, ymd, date) {
      const opponentValue = controlKey === SEARCH_FILTER_COMPONENT_TYPES.daterange.controlKeys.from ? this.dateTill : this.dateFrom

      if (opponentValue !== null) {
        const currentTimestamp = new Date(ymd).getTime()
        const opponentTimestamp = new Date(opponentValue).getTime()

        return controlKey === SEARCH_FILTER_COMPONENT_TYPES.daterange.controlKeys.from ? currentTimestamp > opponentTimestamp : currentTimestamp < opponentTimestamp
      }

      return false
    },
    updateDatepicker (control, value) {
      const fromControl = this.filter.controls.find(c => c.key === SEARCH_FILTER_COMPONENT_TYPES.daterange.controlKeys.from) || {}
      const tillControl = this.filter.controls.find(c => c.key === SEARCH_FILTER_COMPONENT_TYPES.daterange.controlKeys.till) || {}

      if (control.id === fromControl.id && !value && tillControl.value !== SEARCH_FILTER_EMPTY_CONTROL_VALUES.daterange) {
        this.updateControl(control, SEARCH_FILTER_COMPONENT_TYPES.daterange.minDate)
      } else if (control.id === tillControl.id && value && fromControl.value === SEARCH_FILTER_EMPTY_CONTROL_VALUES.daterange) {
        fromControl.value = SEARCH_FILTER_COMPONENT_TYPES.daterange.minDate
        this.updateControl(control, value || SEARCH_FILTER_EMPTY_CONTROL_VALUES.daterange)
      } else {
        this.updateControl(control, value || SEARCH_FILTER_EMPTY_CONTROL_VALUES.daterange)
      }

      if (fromControl.value === SEARCH_FILTER_COMPONENT_TYPES.daterange.minDate) fromControl.value = SEARCH_FILTER_EMPTY_CONTROL_VALUES.daterange
    }
  }
}
</script>

<style lang="scss">
$filterdaterange-controls-control-input-padding-x: $input-padding-x-sm !default;

.search-filter-daterange {
  .filter-controls {
    .controls-control {
      display: block;

      .control-datepicker {
        .form-control {
          padding-left: $filterdaterange-controls-control-input-padding-x;
          padding-right: $filterdaterange-controls-control-input-padding-x;
          border: 0 none;

          .btn {
            padding-top: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
