<script>
import { SEARCH_FILTER_EXPANDED_DEFAULT, SEARCH_FILTER_EMPTY_CONTROL_VALUES } from '@/assets/js/config/client'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    const storedExpanded = JSON.parse(window.sessionStorage.getItem(this.filter.id))

    return {
      expanded: storedExpanded !== null ? storedExpanded : SEARCH_FILTER_EXPANDED_DEFAULT
    }
  },
  computed: {
    filterSetCount () {
      return this.filter.controls.filter(c => c.value !== SEARCH_FILTER_EMPTY_CONTROL_VALUES[this.filter.type]).length
    },
    allFilterSet () {
      return this.filter.controls.every(c => c.value !== SEARCH_FILTER_EMPTY_CONTROL_VALUES[this.filter.type])
    },
    noneFilterSet () {
      return this.filter.controls.every(c => c.value === SEARCH_FILTER_EMPTY_CONTROL_VALUES[this.filter.type])
    }
  },
  methods: {
    toggleExpanded (state = null) {
      this.expanded = state !== null ? state : !this.expanded
      window.sessionStorage.setItem(this.filter.id, this.expanded)
      this.$emit(`filter:${this.expanded ? 'expanding' : 'collapsing'}`)
    },
    setControls (value = null) {
      if (value !== null) {
        this.filter.controls.forEach(c => { c.value = value })
        this.$emit('filter:change', this.filter)
      }
    },
    resetControls () {
      this.filter.controls.forEach(c => { c.value = SEARCH_FILTER_EMPTY_CONTROL_VALUES[this.filter.type] })
      this.$emit('filter:change', this.filter)
    },
    updateControl (control, value) {
      const udpateControl = this.filter.controls.find(c => c.id === control.id) || {}

      udpateControl.value = value
      this.$emit('filter:change', this.filter)
    }
  }
}
</script>

<style lang="scss">
$searchfilter-min-width: 240px !default;
$searchfilter-border-width: $border-width !default;
$searchfilter-border-color: $border-color !default;

$searchfilter-count-size: $spacer * 1.1 !default;
$searchfilter-count-bg: $primary !default;
$searchfilter-count-font-size: $font-size-sm !default;
$searchfilter-count-font-weight: $font-weight-base !default;
$searchfilter-count-color: $white !default;

$searchfilter-header-padding-x: $spacer * 1.5 !default;

$searchfilter-body-padding-x: $searchfilter-header-padding-x !default;

$searchfilter-expander-icon-gap: $spacer * 0.15 !default;
$searchfilter-expander-icon-color: $primary !default;
$searchfilter-expander-icon-transition: all cubic-bezier(0.35, 0.05, 0.4, 1.5) 300ms !default;

$searchfilter-controls-meta-margin: 0 0 ($spacer * 0.75) !default;
$searchfilter-controls-meta-btn-font-size: $font-size-sm !default;

$searchfilter-controls-padding: 0 0 $spacer !default;
$searchfilter-controls-control-margin: ($spacer * 0.25) 0 !default;
$searchfilter-controls-control-font-size: $font-size-sm !default;
$searchfilter-controls-control-font-weight: $font-weight-bold !default;

$searchfilter-controls-control-count-gap: $spacer * 0.5 !default;

.search-filter {
  position: relative;
  min-width: $searchfilter-min-width;
  border-bottom: $searchfilter-border-width solid $searchfilter-border-color;

  + .search-filter {
    margin-top: $searchfilter-border-width * -1;
  }

  .filter-header {
    display: flex;
    justify-content: stretch;
    align-items: center;
    padding-left: $searchfilter-header-padding-x;
    padding-right: $searchfilter-header-padding-x;
  }

  .filter-body {
    padding-left: $searchfilter-body-padding-x;
    padding-right: $searchfilter-body-padding-x;
  }

  .filter-expander {
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    text-decoration: none;
    border: 0 none;
    box-shadow: none!important;
    color: inherit;
    text-decoration: none!important;

    .bi {
      margin-right: $searchfilter-expander-icon-gap;
      color: $searchfilter-expander-icon-color;
      transform: rotate(-90deg);
      transition: $searchfilter-expander-icon-transition;
    }

    .filter-set-count {
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: $searchfilter-count-size * 0.5;
      margin-right: $searchfilter-count-size * 0.5;
      padding-left: $searchfilter-count-size * 0.2;
      padding-right: $searchfilter-count-size * 0.2;
      min-width: $searchfilter-count-size;
      height: $searchfilter-count-size;
      background-color: $searchfilter-count-bg;
      font-size: $searchfilter-count-font-size;
      font-weight: $searchfilter-count-font-weight;
      color: $searchfilter-count-color;
      line-height: 1;
      border-radius: $searchfilter-count-size;
    }
  }

  .filter-controls {
    padding: $searchfilter-controls-padding;

    .controls-meta {
      margin: $searchfilter-controls-meta-margin;

      .btn {
        display: block;
        padding: 0;
        font-size: $searchfilter-controls-meta-btn-font-size;
        line-height: 1;
      }
    }

    .controls-control {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: $searchfilter-controls-control-margin;
      font-size: $searchfilter-controls-control-font-size;
      font-weight: $searchfilter-controls-control-font-weight;

      .control-input,
      .control-select,
      .control-textarea,
      .control-checkbox,
      .control-radio,
      .control-file,
      .control-spinbutton,
      .control-datepicker {
        margin: 0;
        width: 100%;
      }

      .custom-control-label {
        padding-top: ($font-size-base - $searchfilter-controls-control-font-size) * 0.5;
      }

      .control-count {
        margin-left: $searchfilter-controls-control-count-gap;
      }
    }
  }

  &.is-expanded {
    .filter-expander {
      .bi {
        transform: rotate(0deg);
      }
    }
  }

  .expand-enter-active,
  .expand-leave-active {
    transition-duration: 200ms;
  }
}
</style>
