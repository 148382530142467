<template>
  <div>
    <!-- if this is active route (e.g. /locations) -->
    <template v-if="isCurrentRoute">
      <h2>{{ $t('views.locations.events.title') }}</h2>

      <b-row class="mb-3" align-h="between">
        <b-col cols="auto"></b-col>
        <b-col cols="auto">
          <b-button variant="outline-primary" @click="navigate('Locations.Events.Add')"><b-icon icon="plus"/> {{ $t('views.locations.events.button.addevent') }}</b-button>
        </b-col>
      </b-row>

      <entity-entry-table :entityKey="eCurrent.key" :entityBy="eParent" :tableSettings="table">
        <template #cell(type)="data">
          <b-badge variant="dark">{{ data.cell.value }}</b-badge>
        </template>
        <template #cell(delete)="data">
        <b-button variant="danger" @click="showKillModal(data.cell.item)">
          <b-icon icon="trash"/>{{ $t('views.locations.events.button.delete') }}
        </b-button>
      </template>
      </entity-entry-table>

      <b-modal v-if="showConfirmationModal" v-model="showConfirmationModal" :title="$t('views.locations.events.popup.title')" hide-footer @ok="deleteEvent(currentKillModalItem)">
        <i18n path="views.locations.events.popup.message">
          <template #event><b-badge variant="info">{{ valueFormatter(definition, 'eventtype', currentKillModalItem.eventtype).value }}</b-badge></template>
          <template #br><br></template>
        </i18n>
        <div class="text-right">
          <b-button class="mr-2" variant="light" @click="closeToast">{{ $t('views.locations.events.popup.button.cancel') }}</b-button>
          <b-button variant="danger" @click="deleteEvent(currentKillModalItem)">
            <b-icon icon="trash"/>{{ $t('views.locations.events.popup.button.delete') }}
          </b-button>
        </div>
      </b-modal>

    </template>

    <!-- if subroute is active route (e.g. /locations/detail) -->
    <section v-else>
      <router-view/>
    </section>
  </div>
</template>

<script>

import { TableHelper } from '@/assets/js/helper/table'

import MixinEntityBase from '@/components/mixins/EntityBase'
import EntityEntryTable from '@/components/entity/EntryTable'

import { valueFormatter } from '@/assets/js/helper/entity'

const ENTITY_KEY = 'locationevents'

export default {
  name: 'Locations.Events',
  mixins: [MixinEntityBase],
  components: {
    EntityEntryTable
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      table: {
        helper: TableHelper,
        mapper: this.tableEntitiesMapper,
        options: {
          fields: {
            includes: ['delete'],
            excludes: [],
            sorting: ['eventdate', 'eventtype', 'delete'],
            sortable: ['eventdate', 'eventtype'],
            labelKey: `views.${this.$options.name.toLowerCase()}.table.label.{field}`,
            label: {},
            variant: {},
            class: {
              type: 'cell-min',
              status: 'cell-min cell-align-right',
              delete: 'cell-sm cell-align-right'
            },
            thClass: {
              type: 'cell-nowrap'
            },
            tdClass: {}
          }
        },
        events: {
          rowClicked: this.tableRowClicked
        }
      },
      showConfirmationModal: false,
      currentKillModalItem: null
    }
  },
  computed: {
    isCurrentRoute () {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find(c => c.name === this.$options.name) !== undefined
    },
    definition () {
      return this.$store.getters[`${this.eCurrent.key}/definition`]
    }
  },
  methods: {
    valueFormatter,
    tableEntitiesMapper (entities) {
      return entities
        .map(e => Object.assign({
          $routerLink: {
            name: `${this.$options.name}.Detail`,
            params: {
              [this.eCurrent.paramKey]: e.id
            }
          }
        }, e))
    },
    tableRowClicked (entity) {
      this.$router.push(entity.$routerLink)
    },
    deleteEvent (item) {
      this.$store.dispatch(`${this.eCurrent.key}/removeEntity`, item)
      this.closeToast()
    },
    closeToast () {
      this.showConfirmationModal = false
    },
    showKillModal (item) {
      console.log(item)
      this.currentKillModalItem = item
      this.showConfirmationModal = true
    }
  },
  created () {
    TableHelper.rowClassHook(entity => entity.$routerLink ? 'has-routerlink' : null)
  }
}
</script>

<style lang="scss"></style>
