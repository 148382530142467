import router from '@/$plugins/router/core'
import store from '@/$plugins/store/core'
import auth from '../core'

const TOKEN_AUTH_KEY = auth.options.tokenDefaultKey
const TOKEN_REFRESH_KEY = auth.options.tokenRefreshKey

const LOGIN_DATA = auth.options.loginData
const REFRESH_DATA = auth.options.refreshData

function autologin () {
  autorefresh()

  return new Promise((resolve, reject) => {
    if (auth.token(TOKEN_AUTH_KEY)) {
      // refresh existing authentication
      auth.refresh(refreshData())
        .then(response => {
          setTokens(response.data.result)
          store.dispatch('profile/get').finally(resolve)
        })
        .catch(() => {
          logout().finally(resolve)
        })
    } else {
      // check if user is authenticated
      auth.login(loginData())
        .then(response => {
          router.replace({ name: 'Refresh' }) // Remove url-query tokenId
          setTokens(response.data.result)
          store.dispatch('profile/get').finally(resolve)
        })
        .catch(() => {
          resolve()
        })
    }
  })
}

function autorefresh () {
  if (!autorefresh.initialized) {
    autorefresh.timeout = null
    autorefresh.interval = auth.options.refreshData.interval * 60 * 1000

    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) refresh()
    }, false)

    intervalRefresh()

    autorefresh.initialized = true
  }

  function refresh () {
    if (auth.token(TOKEN_REFRESH_KEY)) {
      auth.refresh(refreshData())
        .then(response => {
          setTokens(response.data.result)
          intervalRefresh()
        })
    }
  }

  function intervalRefresh () {
    if (autorefresh.timeout) clearTimeout(autorefresh.timeout)
    autorefresh.timeout = setTimeout(refresh, autorefresh.interval)
  }
}

function login () {
  authenticate()
}

function logout () {
  return new Promise((resolve, reject) => {
    auth.logout()
      .finally(() => {
        setTokens()
        unauthenticate()
      })
  })
}

export default {
  autologin,
  login,
  logout
}

function refreshData () {
  return Object.assign(REFRESH_DATA, {
    data: {
      refreshToken: auth.token(TOKEN_REFRESH_KEY)
    }
  })
}

function loginData () {
  return Object.assign({}, LOGIN_DATA, {
    url: `${LOGIN_DATA.url}${window.location.search}`
  })
}

function setTokens (tokens = {}) {
  auth.token(TOKEN_REFRESH_KEY, tokens.refreshToken || null)
  auth.token(TOKEN_AUTH_KEY, tokens.accessToken || null)
}

function returnUrl (type = 'login') {
  let url = ''

  if (type === 'login') {
    const previousRoute = router.previousRoute
    const isDefined = previousRoute !== null
    const isCurrent = previousRoute === router.currentRoute

    url = isDefined && !isCurrent ? `${window.location.origin}${previousRoute.fullPath}` : window.location.origin
  } else if (type === 'logout') {
    url = `${window.location.origin}/auth/logout`
  }

  return url
}

function urlBase () {
  return (process.env.VUE_APP_API_BASE_URL || window.location.origin).replace(/\/$/, '')
}

function urlQuery (type) {
  const queryParameter = {
    returnUrl: returnUrl(type)
  }

  const existingQuery = window.location.search.substr(1).split('&').filter(q => q !== '')
  const query = Object.keys(queryParameter)
    .map(pKey => queryParameter[pKey] ? `${pKey}=${queryParameter[pKey]}` : null)
    .concat(existingQuery)
    .filter(parameter => parameter !== null)
    .join('&')

  return query.length > 0 ? `?${query}` : ''
}

function authenticate () {
  // redirect to backend-route /authenticate
  window.location = encodeURI(`${urlBase()}/authenticate${urlQuery()}`)
}

function unauthenticate () {
  // redirect to backend-route /authenticate
  window.location = encodeURI(`${urlBase()}/authenticate/logout${urlQuery('logout')}`)
}
