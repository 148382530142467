<template>
  <div class="userprofessionsdetail">
    <h2>{{ $t('views.users.professions.detail.title') }}</h2>

    <div class="detail-alert">
      <b-alert v-if="entity" show :variant="entity.value.isApproved ? 'light' : 'warning'">
        <div class="alert-inner">
          <i18n path="views.users.professions.detail.header.approve.state.base" tag="div" class="mb-2">
            <template #br><br></template>
            <template #state><b-badge :variant="entity.value.isApproved ? 'success' : 'warning'">{{ $t(`views.users.professions.detail.header.approve.state.${entity.value.isApproved}`) }}</b-badge></template>
          </i18n>

          <b-button :variant="entity.value.isApproved ? 'outline-danger' : 'success'" @click="onApproveToggle">
            <b-icon :icon="entity.value.isApproved ? 'close' : 'check'"/> {{ $t(`views.users.professions.detail.header.approve.button.${entity.value.isApproved}`) }}
          </b-button>
        </div>
      </b-alert>
    </div>

    <entity-detail-table
      :entityKey="eParent.key"
      :entityId="eParent.id"

      @control:input="onInput"
      @control:change="onChange"
      @entity:save="onSave"
    />
  </div>
</template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'
import EntityDetailTable from '@/components/entity/DetailTable'

export default {
  name: 'Users.Professions.Detail',
  mixins: [MixinEntityBase],
  components: {
    EntityDetailTable
  },
  computed: {
    entity () {
      return this.$store.getters[`${this.eParent.key}/getEntity`](this.eParent.id) || null
    }
  },
  methods: {
    onApproveToggle () {
      this.$store.dispatch(`${this.eParent.key}/toogleApprove`, this.eParent.id)
    },
    onInput (controlValue) {
      /* do something with controlValue = { [propKey]: value } */
    },
    onChange (controlValue) {
      /* do something with controlValue = { [propKey]: value } */
    },
    onSave (entity) {
      this.$store.dispatch(`${this.eParent.key}/updateEntity`, entity)
    }
  }
}
</script>

<style lang="scss">
$userprofessiondetail-alert-gap: $spacer * 1.5 !default;
$userprofessiondetail-alert-border: 2px solid $border-color !default;

.userprofessionsdetail {
  .detail-alert {
    margin-bottom: $userprofessiondetail-alert-gap;
    border-bottom: $userprofessiondetail-alert-border;

    .alert-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
