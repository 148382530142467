import EXPORT from '@/assets/css/config/variables-export.scss'

const scss = {
  base: {
    black: '#000',
    white: '#fff'
  },
  colors: {
    base: {},
    gray: {},
    theme: {}
  },
  grid: {},
  vars: {}
}

for (const vKey in EXPORT) {
  const color = vKey.match(/^color-(base|gray|theme)-/)
  const grid = vKey.match(/^grid-/)
  const base = [].concat(color || [], grid || [])[0]

  const key = vKey.replace(base, '')
  const value = createValue(EXPORT[vKey])

  if (color) {
    scss.colors[color[1]][key] = value
  } else if (grid) {
    scss.grid[key] = value
  } else {
    scss.vars[key] = value
  }
}

export default scss

function createValue (value) {
  return value.includes(',') ? valueToArray(value) : valueToNumber(value)

  function valueToArray (v) {
    return v
      .replace(/,\s?/g, ',')
      .split(',')
      .map(valueToNumber)
  }

  function valueToNumber (value) {
    return /^([\d.]){1,}(([a-zA-Z]){2,}|%)?$/.test(value) ? { value: parseFloat(value), unit: value.replace(/^\d+/g, '') } : value
  }
}
