<template>
  <section>
    <h1>{{ $t(`views.${$options.name.toLowerCase()}.title`) }}</h1>
    <b-row cols="2">
      <b-col v-for="(entitiesWithRequiredActions, entityKey) in requiredActions" :key="entityKey" class="loader-wrapper">
        <h2 class="h4">{{ $t(`views.dashboard.${entityKey}.requiredactions.title`) }}</h2>
        <loader v-if="isRequiredActions[entityKey].loading"/>
        <entity-required-actions v-else v-for="entity in entitiesWithRequiredActions" :configuration="getConfiguration(entityKey)" :key="entity.id" :entityKey="entityKey" :entity="entity"/>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Loader from '@/components/Loader'
import EntityRequiredActions from '@/components/entity/RequiredActions'

const REQUIRED_ACTION_ENTITY_KEYS = ['locations', 'users', 'orders']
const ADDITIONAL_CONFIGURATION = {
  orders: {
    label: '{orderid} {locationname}'
  }
}

export default {
  name: 'Dashboard',
  components: {
    Loader,
    EntityRequiredActions
  },
  computed: {
    isRequiredActions () {
      return REQUIRED_ACTION_ENTITY_KEYS
        .reduce((entities, eKey) => Object.assign(entities, { [eKey]: this.$store.getters[`${eKey}/is`] }), {})
    },
    requiredActions () {
      return REQUIRED_ACTION_ENTITY_KEYS
        .reduce((entities, eKey) => Object.assign(entities, { [eKey]: this.$store.getters[`${eKey}/getRequiredActions`] }), {})
    }
  },
  methods: {
    getRequiredActivities () {
      REQUIRED_ACTION_ENTITY_KEYS
        .forEach(eKey => {
          this.$store.dispatch(`${eKey}/getRequiredActions`)
        })
    },
    getConfiguration (entityKey) {
      return ADDITIONAL_CONFIGURATION[entityKey]
    }
  },
  created () {
    this.getRequiredActivities()
  }
}
</script>

<style lang="scss"></style>
