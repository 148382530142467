import Vue from 'vue'
import axios from 'axios'

const httpAuth = axios.create({
  name: '$httpAuth',
  baseURL: process.env.VUE_APP_API_BASE_URL || process.env.BASE_URL,
  withCredentials: true
})

const http = axios.create(Object.assign({}, httpAuth.defaults, { name: '$http' }))

const axiosInstances = {
  authentication: httpAuth,
  servicePlatform: http
}

Object.values(axiosInstances)
  .forEach(i => {
    Vue.prototype[i.defaults.name] = i
  })

export {
  http as default,
  httpAuth,
  axiosInstances as instances
}

http({ method: 'get', url: '/api/configuration' })
  .then(response => {
    axiosInstances.servicePlatform.defaults.baseURL = response.data.result.servicePlatformBaseUrl
  })
