export default {
  namespaced: true,
  state: {
    baseUrl: process.env.VUE_APP_API_BASE_URL || process.env.BASE_URL,
    isOnline: false
  },
  getters: {
    get: state => state,
    getBaseUrl: state => state.baseUrl
  },
  mutations: {},
  actions: {}
}
