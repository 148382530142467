<template>
    <div class="module">
      <div class="module-title">
        <h2>{{ $t('views.locations.management.title') }}</h2>
      </div>

     <div class="module">
      <loader v-if="is.loading"/>
      <div v-else class="action action-passwordreset">
        <h4 class="h5">{{ $t('views.locations.management.removelocation') }}</h4>

        <div v-if="isDeletable" class="action-info">
          <b-alert class="action-description" variant="info" show><b-icon icon="circle-info"/> {{ $t('views.locations.management.action.removelocation.description') }}</b-alert>

          <div>
            <b-button class="action-button" variant="danger" @click="deleteLocation(deleteConfirmationModal.item)"><b-icon icon="trash"/> {{ $t('views.locations.management.action.removelocation.button') }}</b-button>
          </div>
        </div>
        <div v-else class="action-info">
          <b-alert v-for="(reason, index) in reasons" :key="index" class="action-description" variant="warning" show>
            <b-icon icon="circle-info"/> {{ reason }}</b-alert>
        </div>
      </div>

    </div>

    <b-modal
      :id="deleteConfirmationModal.id"
      v-model="deleteConfirmationModal.show"
      size="md"
      centered
      scrollable
      no-fade
      header-bg-variant="warning"
      header-text-variant="black"
    >
      <template #modal-header-close><b-icon icon="close"/></template>
      <template #modal-title>{{ $t('views.locations.management.modal.header.title') }}</template>

      <template v-if="deleteConfirmationModal.show">
        <p>{{ $t('views.locations.management.modal.body.confirmation') }}</p>
      </template>

      <template #modal-footer>
        <template v-if="deleteConfirmationModal.show">
          <b-button variant="light" @click="closeDeleteConfirmation">{{ $t('views.locations.management.modal.footer.button.abort') }}</b-button>
          <b-button variant="danger" @click="deleteLocation(deleteConfirmationModal.item, true)" :disabled="deleteIsLoading"><loader inline v-if="deleteIsLoading"/><b-icon v-else icon="trash"/> {{ $t('views.locations.management.modal.footer.button.confirm') }}</b-button>
        </template>
      </template>
    </b-modal>

  </div>
</template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'
import Loader from '@/components/Loader'

const ENTITY_KEY = 'locationmanagement'

export default {
  name: 'Locations.Management',
  mixins: [MixinEntityBase],
  components: {
    Loader
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      isLoading: true,
      isDeletable: false,
      deleteIsLoading: false,
      reasons: [],
      deleteConfirmationModal: {
        id: 'deleteConfirmationModal',
        show: false,
        item: null
      }
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.eParent.key}/is`]
    }
  },
  methods: {
    async getIsDeletable () {
      this.isLoading = true
      const isDeletableResult = await this.$store.dispatch(`${this.eParent.key}/isDeletable`, this.eParent.id)

      this.isDeletable = isDeletableResult.success
      this.reasons = isDeletableResult.result.filter(r => !r.isDeletable).map(r => r.reason)
      this.isLoadig = false
    },
    async deleteLocation (item = {}, confirmed = false) {
      if (!confirmed) {
        this.openDeleteConfirmation(item)
      } else {
        this.deleteIsLoading = true
        await this.$store.dispatch(`${this.eParent.key}/removeEntity`, this.eParent)
        setTimeout(() => {
          this.$router.push({ name: 'Locations' })
        }, 1000)
      }
    },
    openDeleteConfirmation (item) {
      this.deleteConfirmationModal.item = item
      this.deleteConfirmationModal.show = true
    },
    closeDeleteConfirmation () {
      this.deleteConfirmationModal.show = false
      this.deleteConfirmationModal.item = null
    }
  },
  created () {
    this.getIsDeletable()
  }
}
</script>

<style lang="scss"></style>
