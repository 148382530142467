<template>
  <router-link
    v-if="isAllowedRoute"
    class="navigation-link"
    :to="{ name: route.name, params: routeParams }"
    :disabled="isDisabledRoute"
    :title="hasUpdates ? $t('components.navigation.navigationlink.hasUpdates') : ''"
  >
    <b-icon v-if="route.meta.navi.icon" :icon="route.meta.navi.icon" class="link-icon"/>
    <span>
      {{ routeName }}
      <b-icon v-if="hasUpdates" icon="b-circle-fill" variant="danger" scale="0.6" class="link-updatesnotification-icon"/>
    </span>
  </router-link>
</template>

<script>
import { getRouteLanguage } from '@/$plugins/i18n/hooks/route-language'

export default {
  name: 'NavigationLink',
  props: {
    naviType: {
      type: String,
      default: 'main'
    },
    route: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    return {
      hasUpdates: false
    }
  },
  computed: {
    userRoles () {
      return this.$store.getters['profile/getRoles'] || []
    },
    isAllowedRoute () {
      const auth = this.route.meta.auth
      const roles = this.userRoles || []

      const route = {
        isPublic: !auth,
        isPrivate: auth === true,
        isRoleSpecific: !!auth && typeof auth === 'object'
      }

      const user = {
        isLoggedIn: this.$auth.check(),
        isRoleAuthenticated: [].concat(auth || []).some(role => roles.includes(role))
      }

      return route.isPublic || (route.isPrivate && user.isLoggedIn) || (route.isRoleSpecific && user.isLoggedIn && user.isRoleAuthenticated)
    },
    isDisabledRoute () {
      const navi = this.route.meta.navi || {}

      if (navi.calcDisabled && navi.calcDisabled()) return true

      return false
    },
    routeParams () {
      return {
        lang: getRouteLanguage(this.$i18n.locale)
      }
    },
    routeName () {
      const routeNameKey = `views.${this.route.name.toLowerCase()}.navigation.${this.naviType}`
      const routeMainKey = `${routeNameKey}.@`

      return this.$te(routeMainKey) ? this.$t(routeMainKey) : this.$t(routeNameKey)
    }
  },
  created () {
    this.$root.$on(`route:${this.route.name}:hasUpdates`, () => {
      this.hasUpdates = true
    })
  }
}
</script>

<style lang="scss">
$navigation-link-padding-y: $spacer * 0.4 !default;
$navigation-link-padding-x: $spacer * 1.5 !default;
$navigation-link-font-family: $font-family-base !default;
$navigation-link-font-size: $font-size-base !default;
$navigation-link-font-weight: $font-weight-bold !default;
$navigation-link-color: inherit !default;
$navigation-link-line-height: 1.1 !default;
$navigation-link-icon-gap: $spacer * 0.5 !default;
$navigation-link-icon-size: $spacer * 1.25 !default;
$navigation-link-icon-color: inherit !default;
$navigation-link-state-unread-top: -0.25em !default;
$navigation-link-state-unread-left: -0.1em !default;
$navigation-link-state-unread-size: $navigation-link-font-size * 1.25 !default;

$navigation-link-hover-color: $primary !default;
$navigation-link-hover-icon-color: inherit !default;

$navigation-link-active-color: $navigation-link-hover-color !default;
$navigation-link-active-icon-color: $navigation-link-hover-icon-color !default;

$navigation-link-exact-active-color: $navigation-link-hover-color !default;
$navigation-link-exact-active-icon-color: $navigation-link-hover-icon-color !default;

$navigation-link-disabled-color: $gray-300 !default;
$navigation-link-disabled-icon-color: $navigation-link-disabled-color !default;

.navigation-link {
  display: flex;
  align-items: center;
  padding: $navigation-link-padding-y $navigation-link-padding-x;
  font-family: $navigation-link-font-family;
  font-size: $navigation-link-font-size;
  font-weight: $navigation-link-font-weight;
  color: $navigation-link-color;
  line-height: $navigation-link-line-height;
  text-align: left;
  text-decoration: none;

  > .bi {
    flex: 0 0 auto;
    height: 100%;
    margin-right: $navigation-link-icon-gap;
    font-size: $navigation-link-icon-size;
    color: $navigation-link-icon-color;
  }

  > .state-unread {
    position: relative;
    top: $navigation-link-state-unread-top;
    left: $navigation-link-state-unread-left;
    font-size: $navigation-link-state-unread-size;
  }

  > .state-completeness {
    flex: 0 0 auto;
    margin-right: $navigation-link-icon-gap;
  }

  &.dropdown {
    > .dropdown-toggle {
      padding: 0;
      text-align: inherit;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      line-height: inherit;
      text-align: inherit;
      text-decoration: inherit;

      > .bi {
        margin-right: $navigation-link-icon-gap;
      }
    }
  }

  &:hover {
    color: $navigation-link-hover-color;
    text-decoration: none;
    svg.bi { color: $navigation-link-hover-icon-color; }
  }

  &.router-link-active {
    color: $navigation-link-active-color;
    svg.bi { color: $navigation-link-active-icon-color; }
  }

  &.router-link-exact-active {
    color: $navigation-link-exact-active-color;
    svg.bi { color: $navigation-link-exact-active-icon-color; }
  }

  &.disabled,
  &[disabled],
  &:disabled {
    color: $navigation-link-disabled-color;
    cursor: default;
    svg.bi { color: $navigation-link-disabled-icon-color; }
  }
}
</style>
