import DE_CH_MESSAGES from '@/assets/translations/messages/de-CH.json'
import DE_CH_DATETIME from '@/assets/translations/datetime/de-CH.json'

const LANGUAGES = {
  'de-CH': DE_CH_MESSAGES
}

const DATETIMES = {
  'de-CH': DE_CH_DATETIME
}

const AVAILABLE_LANGUAGES = {
  exact: Object.keys(LANGUAGES),
  loose: Object.keys(LANGUAGES).map(lang => lang.substr(0, 2))
}

const BROWSER_LANGUAGE = {
  exact: window.navigator.language || '',
  loose: (window.navigator.language || '').substr(0, 2)
}

// Define first available Language as default
const DEFAULT_LANGUAGE = AVAILABLE_LANGUAGES.exact[0]

// Define calculated initial Language
const INITIAL_LANGUAGE = _getInitialLanguage()

const AVAILABLE_DATE_I18N = AVAILABLE_LANGUAGES.loose.reduce((locales, lKey) => Object.assign(locales, { [lKey]: require(`date-fns/locale/${lKey}/index.js`) }), {})

function getExactLanguage (language = '') {
  return AVAILABLE_LANGUAGES.exact[AVAILABLE_LANGUAGES.loose.findIndex(lang => lang === language)]
}

function getLooseLanguage (language = '') {
  return AVAILABLE_LANGUAGES.loose[AVAILABLE_LANGUAGES.exact.findIndex(lang => lang === language)]
}

function setLanguage (language = '', fallback = '') {
  let selectedLanguage = null

  if (AVAILABLE_LANGUAGES.exact.includes(language)) {
    // Set language by exact match ("{language}-{Country}" e.g. "de-CH")
    selectedLanguage = language
  } else if (AVAILABLE_LANGUAGES.loose.includes(language)) {
    // Set language by loose match ("{language}" e.g. "de")
    selectedLanguage = getExactLanguage(language)
  } else {
    // Set fallback language
    selectedLanguage = AVAILABLE_LANGUAGES.exact.includes(fallback) ? fallback : DEFAULT_LANGUAGE
    console.warn(`Can't set language to "${language}"!\nSystem falls back to "${selectedLanguage}" instead.`)
  }

  _setDocumentLanguage(selectedLanguage)
  return selectedLanguage
}

function _getInitialLanguage () {
  let initialLanguage = null

  if (AVAILABLE_LANGUAGES.exact.includes(BROWSER_LANGUAGE.exact)) {
    // Get language by exact match ("{language}-{Country}" e.g. "de-CH")
    initialLanguage = BROWSER_LANGUAGE.exact
  } else if (AVAILABLE_LANGUAGES.loose.includes(BROWSER_LANGUAGE.loose)) {
    // Get language by loose match ("{language}" e.g. "de")
    initialLanguage = getExactLanguage(BROWSER_LANGUAGE.loose)
  } else {
    // Get default
    initialLanguage = DEFAULT_LANGUAGE
  }

  return initialLanguage
}

function _setDocumentLanguage (language = '') {
  document.documentElement.setAttribute('lang', getLooseLanguage(language))
}

export {
  LANGUAGES as default,
  DATETIMES,
  AVAILABLE_LANGUAGES,
  BROWSER_LANGUAGE,
  DEFAULT_LANGUAGE,
  INITIAL_LANGUAGE,
  AVAILABLE_DATE_I18N,
  getExactLanguage,
  getLooseLanguage,
  setLanguage
}
