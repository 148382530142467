import Vue from 'vue'
import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP } from '@/assets/js/config/server'

import http from '@/$plugins/http'

import base from '../base'
import IS from '../base/is'
import CHANGE_REQUESTS from '../base/change-requests'
import NOTES from '../base/entity-notes'
import REQUIRED_ACTIONS from '../base/entity-requiredactions'
import SEARCH from '../base/entity-search'
import MAINENTITY from '../base/entity-main'

export default {
  namespaced: true,
  state: base.createState(IS.state, CHANGE_REQUESTS.state, NOTES.state, REQUIRED_ACTIONS.state, SEARCH.state, MAINENTITY.state, { /* define custom state-properties */ }),
  getters: base.createGetters(IS.getters, CHANGE_REQUESTS.getters, NOTES.getters, REQUIRED_ACTIONS.getters, SEARCH.getters, MAINENTITY.getters, { /* define custom getters */ }),
  mutations: base.createMutations(IS.mutations, CHANGE_REQUESTS.mutations, NOTES.mutations, REQUIRED_ACTIONS.mutations, SEARCH.mutations, MAINENTITY.mutations, {
    setBasketItem (state, { key, orderId, basketItem }) {
      const index = state.entities.findIndex(item => {
        return (item.value.id === orderId)
      })
      const orderItem = {
        ...state.entities[index],
        value: {
          ...state.entities[index].value,
          executedBasket: {
            ...state.entities[index].value.executedBasket,
            items: state.entities[index].value.executedBasket.items.map(x => {
              if (x.basketItemId === basketItem.basketItemId) {
                return { ...x, quantity: basketItem.quantity, basketItemState: basketItem.itemState }
              }
              return x
            })
          }
        }
      }
      Vue.set(state.entities, index, orderItem)
    }
  }),
  actions: base.createEntityActions(STOREMODULE_ENDPOINTMODULE_MAP.orders, STOREMODULE_ENDPOINT_MAP.orders, IS.actions, CHANGE_REQUESTS.actions, NOTES.actions, REQUIRED_ACTIONS.actions, SEARCH.actions, MAINENTITY.actions, {
    triggerSetTransmissionState (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = {}) {
        commit('setSending', { key: 'triggerSetTransmissionState', sending: true })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${serverEntityName}/${options.entityId}/transmission/${options.transmissionType}/set/${options.transmissionState}?treatAsOffer=${options.treatAsOffer !== undefined && options.treatAsOffer}`
          })
            .then(response => {
              commit('setEntity', { key: entityKey, entity: response.data.result.value })
              resolve(getters.getEntity(response.data.result.value.id))
            })
            .finally(() => {
              commit('setLoading', { key: 'triggerSetTransmissionState', loading: false })
            })
        })
      }
    },
    updateOrderItem (entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = {}) {
        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/order/api/orderitems/updateByOrderIdAndBasketItemId/${options.orderId}/${options.basketItemId}`,
            data: options.data
          })
            .then(response => {
              commit('setBasketItem', { key: entityKey, orderId: options.orderId, basketItem: response.data.result.value })
              resolve(getters.getEntity(options.orderId))
            })
        })
      }
    }
  })
}
