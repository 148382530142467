<template>
  <div>
    <loader v-if="is.loading"/>
    <template v-else>
      <order-basket :order="entity"/>
      <br />
      <order-actions :entityKey="eParent.key" :order="entity" />
    </template>

  </div>
</template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'
import OrderBasket from '@/components/order/OrderBasket'
import Loader from '@/components/Loader'
import OrderActions from '@/components/order/OrderActions'

export default {
  name: 'Orders.Detail',
  mixins: [MixinEntityBase],
  components: {
    OrderBasket,
    Loader,
    OrderActions
  },
  computed: {
    is () {
      return this.$store.getters[`${this.eParent.key}/is`]
    },
    entity () {
      return this.$store.getters[`${this.eParent.key}/getEntityUnwrapped`](this.eParent.id) || {}
    }
  }
}
</script>

<style lang="scss"></style>
