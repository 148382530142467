<template>
  <div>
    <!-- if this is active route (e.g. /users) -->
    <loader v-if="isLoading" />

    <template v-if="isCurrentRoute">
      <entity-entry-header :entityKey="eCurrent.key" :title="$t(`views.${eCurrent.key}.title`)" />
      <b-row class="mb-3" align-h="between">
        <b-col cols="auto"></b-col>
        <b-col cols="auto">
          <b-button class="ml-1" variant="outline-primary" @click="exportLocations">{{ $t('views.users.locations.button.exportlocations') }}</b-button>
        </b-col>
      </b-row>

      <entity-entry-table :entityKey="eCurrent.key" :tableSettings="table" searchable>
        <template #cell(industryCode)="data">
          <b-badge variant="dark" v-html="valueFormatter(data.definition, data.cell.field.key, data.cell.value).html" />
        </template>

        <template #cell(requiredactions)="data">
          <div class="badge-group">
            <b-badge v-for="action in data.cell.item.requiredactions" :key="action" variant="warning">{{ action }}</b-badge>
          </div>
        </template>
      </entity-entry-table>
    </template>

    <!-- if subroute is active route (e.g. /users/detail) -->
    <section v-else>
      <entity-detail-header :entityKey="eCurrent.key" :entityId="eCurrent.id">
        <template #default="data">
          <dl class="detailheader-list">
            <dt class="sr-only">{{ data.definition.$properties.name.translations[$i18n.locale].name }}</dt>
            <dd>
              <h1>{{ data.entity.name }}</h1>
            </dd>

            <div class="w-100"></div>
            <dt>EbiOnlineId:</dt>
            <dd>{{ data.entity.id }}</dd>

            <div class="w-100"></div>
            <dt>{{ data.definition.$properties.industryCode.translations[$i18n.locale].name }}</dt>
            <dd><b-badge variant="dark" v-html="valueFormatter(data.definition, 'industryCode', data.entity.industryCode).html" /></dd>

            <div class="w-100"></div>
            <dt>{{ data.definition.$properties.glnNumber.translations[$i18n.locale].name }}</dt>
            <dd>{{ data.entity.glnNumber }}</dd>

          </dl>
        </template>
      </entity-detail-header>

      <router-view />
    </section>
  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_UNKNOWN } from '@/assets/js/config/client'
import Loader from '@/components/Loader'

import { valueFormatter } from '@/assets/js/helper/entity'
import { TableHelper } from '@/assets/js/helper/table'

import EntityDetailHeader from '@/components/entity/DetailHeader'
import EntityEntryHeader from '@/components/entity/EntryHeader'
import EntityEntryTable from '@/components/entity/EntryTable'
import MixinEntityBase from '@/components/mixins/EntityBase'

const ENTITY_KEY = 'locations'

export default {
  name: 'Locations',
  mixins: [MixinEntityBase],
  components: {
    EntityEntryHeader,
    EntityEntryTable,
    EntityDetailHeader,
    Loader
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      isLoading: false,
      table: {
        helper: TableHelper,
        mapper: this.tableEntitiesMapper,
        options: {
          fields: {
            includes: [],
            excludes: [],
            sorting: [CLASS_TABLE_FIELD_KEY_UNKNOWN, 'requiredactions'],
            labelKey: `views.${this.$options.name.toLowerCase()}.table.label.{field}`,
            class: {
              requiredactions: 'cell-align-right'
            },
            thClass: {
              requiredactions: 'cell-min cell-nowrap'
            }
          }
        },
        events: {
          rowClicked: this.tableRowClicked
        }
      }
    }
  },
  computed: {
    isCurrentRoute () {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find(c => c.name === this.$options.name) !== undefined
    }
  },
  methods: {
    valueFormatter,
    tableEntitiesMapper (entities, definition) {
      return entities
        .map(e => {
          return Object.assign({}, e, {
            $routerLink: {
              name: `${this.$options.name}.Detail`,
              params: {
                [this.eCurrent.paramKey]: e.id
              }
            }
          })
        })
    },
    exportLocations () {
      this.isLoading = true
      this.$store.dispatch(`${this.eCurrent.key}/downloadCsvFile`)
        .then(response => {
          this.isLoading = false
          if (!response || !response.data) {
            console.error('No data received for download')
            return
          }
          const contentDisposition = response.headers['content-disposition']
          let filename = 'Export.csv'
          if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename\*?="?([^;"]+)/)
            if (filenameMatch.length > 1) {
              filename = filenameMatch[1]
            }
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        })
        .catch(error => {
          console.error('Error during export:', error)
        })
    },
    tableRowClicked (entity) {
      this.$router.push(entity.$routerLink)
    }
  },
  created () {
    TableHelper.rowClassHook(entity => entity.$routerLink ? 'has-routerlink' : null)
  }
}
</script>

<style lang="scss"></style>
