<template>
  <div :class="['loader', `is-type-${type}`, { 'is-inline': inline }]">
    <b-iconstack class="loader-icon" :font-scale="scale">
      <template v-if="type === 'dots'">
        <b-icon stacked icon="loader-dots"/>
      </template>

      <template v-else>
        <b-icon stacked icon="loader-circle-back"/>
        <b-icon stacked icon="loader-circle-front"/>
      </template>
    </b-iconstack>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    type: {
      type: String,
      default: 'default' // 'default' | 'dots'
    },
    inline: {
      type: Boolean,
      default: false
    },
    scale: {
      type: [Number, String],
      default: 1
    }
  }
}
</script>

<style lang="scss">
$loader-wrapper-empty-height: 200px !default;

$loader-icon-size: $font-size-base * 5 !default;

$loader-icon-circle-back-color: $primary !default;
$loader-icon-circle-front-color: $gray-300 !default;

$loader-icon-dots-color: $primary !default;

$loader-icon-animation-duration: 1.5s !default;

.loader-wrapper {
  position: relative;

  &.empty {
    height: $loader-wrapper-empty-height;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  z-index: 2000;

  .loader-icon {
    transform: translate(-50%, -50%);
    font-size: $loader-icon-size;
  }

  &.is-inline {
    display: inline-block;
    position: static;
    width: auto;
    height: auto;
    vertical-align: middle;

    .loader-icon {
      display: block;
      transform: none;
      font-size: inherit;
    }
  }

  &.is-type-default {
    .loader-icon {
      .bi-loader-circle-front {
        color: $loader-icon-circle-front-color;

        .loader-spinner { transform-origin: center center; animation: loader-spin $loader-icon-animation-duration cubic-bezier(0.8, 0.25, 0.45, 0.8) infinite; }
      }

      .bi-loader-circle-back {
        color: $loader-icon-circle-back-color;
      }
    }
  }

  &.is-type-dots {
    .loader-icon {
      .bi-loader-dots {
        color: $loader-icon-dots-color;

        .loader-dot-1 { transform-origin: 25% center; animation: loader-pulse $loader-icon-animation-duration ease infinite ($loader-icon-animation-duration / 6 * 2); }
        .loader-dot-2 { transform-origin: center center; animation: loader-pulse $loader-icon-animation-duration ease infinite ($loader-icon-animation-duration / 6); }
        .loader-dot-3 { transform-origin: 75% center; animation: loader-pulse $loader-icon-animation-duration ease infinite; }
      }
    }
  }
}

@keyframes loader-spin {
 0% { transform: rotate(0deg); }
 100% { transform: rotate(360deg); }
}

@keyframes loader-pulse {
  0% { transform: scale(0); }
  70% { transform: scale(1); }
  100% { transform: scale(0); }
}
</style>
