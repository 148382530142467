<template>
  <div class="dev">
    <h1>Frontend Build Information</h1>

    <div class="module">
      <div class="module-title">Environment</div>

      <div class="module-infos">
        <b-form-row class="info-row">
          <b-col md="2" class="row-label">NodeJS Version</b-col>
          <b-col class="row-value">{{ project.env.NODE_VERSIONS.node }}</b-col>
        </b-form-row>

        <b-form-row class="info-row">
          <b-col md="2" class="row-label">Mode</b-col>
          <b-col class="row-value">{{ project.env.NODE_ENV }}</b-col>
        </b-form-row>

        <b-form-row class="info-row">
          <b-col md="2" class="row-label">Base Url</b-col>
          <b-col class="row-value">{{ project.env.BASE_URL }}</b-col>
        </b-form-row>

        <b-form-row class="info-row">
          <b-col md="2" class="row-label">API Base Url</b-col>
          <b-col class="row-value">{{ project.env.VUE_APP_API_BASE_URL }}</b-col>
        </b-form-row>
      </div>
    </div>

    <div class="module">
      <div class="module-title">Configuration</div>

      <div class="module-infos">
        <b-form-row class="info-row">
          <b-col md="2" class="row-label">Name</b-col>
          <b-col class="row-value">{{ project.config.name }}</b-col>
        </b-form-row>

        <b-form-row class="info-row">
          <b-col md="2" class="row-label">Version</b-col>
          <b-col class="row-value">{{ project.config.version }}</b-col>
        </b-form-row>

        <b-form-row class="info-row">
          <b-col md="2" class="row-label">Modules</b-col>
          <b-col class="row-value">
            <template v-for="(dValue, dLabel) in project.config.dependencies">
              <b-form-row class="info-row" :key="dLabel">
                <b-col class="row-label">{{ dLabel }}</b-col>
                <b-col class="row-value">{{ dValue }}</b-col>
              </b-form-row>
            </template>
          </b-col>
        </b-form-row>

        <b-form-row class="info-row">
          <b-col md="2" class="row-label">Dev Modules</b-col>
          <b-col class="row-value">
            <template v-for="(dValue, dLabel) in project.config.devDependencies">
              <b-form-row class="info-row" :key="dLabel">
                <b-col class="row-label">{{ dLabel }}</b-col>
                <b-col class="row-value">{{ dValue }}</b-col>
              </b-form-row>
            </template>
          </b-col>
        </b-form-row>
      </div>
    </div>

    <h2 class="mt-4">Dependencys</h2>

    <div class="mt-3">
      <!-- <form-control-input
        :id="dependencySearch.id"
        :label="dependencySearch.label"
        label-cols="auto"
        v-model="dependencySearch.value"
        :placeholder="dependencySearch.placeholder"
      /> -->
    </div>

    <b-form-row class="mt-3">
      <b-col>
        <div class="module">
          <div class="module-title">npm installed dependencys</div>

          <div class="module-infos">
            <template v-for="dependency in npmDependencies">
              <b-form-row class="info-row" :key="`npm_${dependency.label}_${dependency.version}`">
                <b-col class="row-label">{{ dependency.label }}</b-col>
                <b-col cols="auto" class="row-value text-right">{{ dependency.version }}</b-col>
              </b-form-row>
            </template>
          </div>
        </div>
      </b-col>

      <b-col>
        <div class="module">
          <div class="module-title">pnpm installed dependencys</div>

          <div class="module-infos">
            <template v-for="dependency in pnpmDependencies">
              <b-form-row class="info-row" :key="`pnpm_${dependency.label}_${dependency.version}`">
                <b-col class="row-label">{{ dependency.label }}</b-col>
                <b-col cols="auto" class="row-value text-right">{{ dependency.version }}</b-col>
              </b-form-row>
            </template>
          </div>
        </div>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import * as config from '@/../package.json'

const locks = {
  npm: {},
  pnpm: {}
}

try {
  locks.npm = require('@/../package-lock.json')
} catch (e) {
  // console.warn(e)
}

try {
  locks.pnpm = require('@/../pnpm-lock.yaml')
} catch (e) {
  // console.warn(e)
}

const installedDependencies = {
  npm: locks.npm.dependencies || {},
  pnpm: locks.pnpm.packages || {}
}

export default {
  name: 'DevBuild',
  data () {
    return {
      dependencySearch: {
        id: 'BuildDependencySearch',
        label: 'Filter Dependencys',
        value: '',
        placeholder: 'Type dependency name...'
      },
      project: {
        env: process.env,
        config: config.default,
        installedDependencies: {
          npm: Object.keys(installedDependencies.npm)
            .map(dKey => ({ label: dKey, version: installedDependencies.npm[dKey].version, search: `${dKey}::${installedDependencies.npm[dKey].version}`.toLowerCase() }))
            .sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0),
          pnpm: Object.keys(installedDependencies.pnpm)
            .map(p => {
              const split = p.split('/').filter(s => s !== '')
              const label = split.slice(0, -1).join('/')
              const version = split[split.length - 1]

              return {
                label,
                version,
                search: `${label}::${version}`.toLowerCase()
              }
            })
            .sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0)
        }
      }
    }
  },
  computed: {
    dependencySearchFilter () {
      return this.dependencySearch.value.toLowerCase()
    },
    npmDependencies () {
      return this.project.installedDependencies.npm
        .filter(d => d.search.indexOf(this.dependencySearchFilter) >= 0)
    },
    pnpmDependencies () {
      return this.project.installedDependencies.pnpm
        .filter(d => d.search.indexOf(this.dependencySearchFilter) >= 0)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/modules/dev/view.scss';
</style>
