import { USER_ROLES } from '@/assets/js/config/server'

export default [
  {
    path: process.env.VUE_APP_USE_LANG_IN_URL === 'true' ? '/:lang([a-zA-Z][a-zA-Z])?' : '',
    component: { render: h => h('router-view') },
    navigationRoutesEntry: true,
    children: [
      {
        path: '',
        redirect: { name: 'Dashboard' }
      },
      { /* Dashboard */
        name: 'Dashboard',
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "v-dashboard" */ '@/views/Dashboard'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'dashboard',
            exact: true
          }
        }
      },
      { /* Locations */
        name: 'Locations',
        path: 'locations',
        component: () => import(/* webpackChunkName: "v-locations" */ '@/views/Locations'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'locations'
          }
        },
        children: [
          { /* Locations.DefaultRedirect */
            path: ':locationid',
            redirect: { name: 'Locations.Detail' }
          },
          { /* Locations.Detail */
            name: 'Locations.Detail',
            path: ':locationid/detail',
            component: () => import(/* webpackChunkName: "v-locations-detail" */ '@/views/Locations/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'location'
              }
            }
          },
          { /* Locations.Addresses */
            name: 'Locations.Addresses',
            path: ':locationid/addresses',
            component: () => import(/* webpackChunkName: "v-locations-addresses" */ '@/views/Locations/Addresses'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'addresses'
              }
            },
            children: [
              { /* Locations.Addresses.Add */
                name: 'Locations.Addresses.Add',
                path: 'add',
                component: () => import(/* webpackChunkName: "v-users-locations-add" */ '@/views/Locations/Addresses/Add')
              },
              { /* Locations.Addresses.Detail */
                name: 'Locations.Addresses.Detail',
                path: ':locationaddressid/detail',
                component: () => import(/* webpackChunkName: "v-locations-addresses-detail" */ '@/views/Locations/Addresses/Detail'),
                meta: {
                  navi: {
                    arrangement: 'start',
                    icon: 'addresses'
                  }
                }
              }
            ]
          },
          { /* Locations.Users */
            name: 'Locations.Users',
            path: ':locationid/users',
            component: () => import(/* webpackChunkName: "v-locations-users" */ '@/views/Locations/Users'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'location-users'
              }
            }
          },
          { /* Locations.Events */
            name: 'Locations.Events',
            path: ':locationid/events',
            component: () => import(/* webpackChunkName: "v-locations-events" */ '@/views/Locations/Events'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'Calendar2Date'
              }
            },
            children: [
              { /* Locations.Events.Add */
                name: 'Locations.Events.Add',
                path: 'add',
                component: () => import(/* webpackChunkName: "v-users-locations-add" */ '@/views/Locations/Events/Add')
              },
              { /* Locations.Events.Detail */
                name: 'Locations.Events.Detail',
                path: ':locationeventid/detail',
                component: () => import(/* webpackChunkName: "v-locations-addresses-detail" */ '@/views/Locations/Events/Detail'),
                meta: {
                  navi: {
                    arrangement: 'start',
                    icon: 'Calendar2Date'
                  }
                }
              }
            ]
          },
          { /* Locations.Notes */
            name: 'Locations.Notes',
            path: ':locationid/notes',
            component: () => import(/* webpackChunkName: "v-locations-notes" */ '@/views/Locations/Notes'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'notes'
              }
            }
          },
          { /* Locations.Users */
            name: 'Locations.Management',
            path: ':locationid/management',
            component: () => import(/* webpackChunkName: "v-locations-users" */ '@/views/Locations/Management'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'services'
              }
            }
          },
          { /* Locations.ChangeTracking */
            name: 'Locations.ChangeTracking',
            path: ':locationid/changetracking',
            component: () => import(/* webpackChunkName: "v-locations-changetracking" */ '@/views/Locations/ChangeTracking'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'history'
              }
            }
          }
        ]
      },
      { /* Users */
        name: 'Users',
        path: 'users',
        component: () => import(/* webpackChunkName: "v-users" */ '@/views/Users'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'users'
          }
        },
        children: [
          { /* Users.Add */
            name: 'Users.Add',
            path: 'add',
            component: () => import(/* webpackChunkName: "v-users-add" */ '@/views/Users/Add')
          },
          { /* Users.DefaultRedirect */
            path: ':userid',
            redirect: { name: 'Users.Detail' }
          },
          { /* Users.Detail */
            name: 'Users.Detail',
            path: ':userid/detail',
            component: () => import(/* webpackChunkName: "v-users-detail" */ '@/views/Users/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'user'
              }
            }
          },
          { /* Users.Addresses */
            name: 'Users.Addresses',
            path: ':userid/addresses',
            component: () => import(/* webpackChunkName: "v-users-addresses" */ '@/views/Users/Addresses'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'addresses'
              }
            },
            children: [
              { /* Users.Addresses.Add */
                name: 'Users.Addresses.Add',
                path: 'add',
                component: () => import(/* webpackChunkName: "v-users-addresses-add" */ '@/views/Users/Addresses/Add')
              },
              { /* Users.Addresses.Detail */
                name: 'Users.Addresses.Detail',
                path: ':useraddressid/detail',
                component: () => import(/* webpackChunkName: "v-users-addresses-detail" */ '@/views/Users/Addresses/Detail'),
                meta: {
                  navi: {
                    arrangement: 'start',
                    icon: 'addresses'
                  }
                }
              }
            ]
          },
          { /* Users.Professions */
            name: 'Users.Professions',
            path: ':userid/professions',
            component: () => import(/* webpackChunkName: "v-users-professions" */ '@/views/Users/Professions'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'professions'
              }
            },
            children: [
              { /* Users.Professions.Add */
                name: 'Users.Professions.Add',
                path: 'add',
                component: () => import(/* webpackChunkName: "v-users-professions-add" */ '@/views/Users/Professions/Add')
              },
              { /* Users.Professions.Detail */
                name: 'Users.Professions.Detail',
                path: ':userprofessionid/detail',
                component: () => import(/* webpackChunkName: "v-users-professions-detail" */ '@/views/Users/Professions/Detail'),
                meta: {
                  navi: {
                    arrangement: 'start',
                    icon: 'professions'
                  }
                }
              }
            ]
          },
          { /* Users.Logins */
            name: 'Users.Logins',
            path: ':userid/logins',
            component: () => import(/* webpackChunkName: "v-users-logins" */ '@/views/Users/Logins'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'accounts'
              }
            }
          },
          { /* Users.Services */
            name: 'Users.Services',
            path: ':userid/services',
            component: () => import(/* webpackChunkName: "v-users-services" */ '@/views/Users/Services'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'services'
              }
            }
          },
          { /* Users.Locations */
            name: 'Users.Locations',
            path: ':userid/locations',
            component: () => import(/* webpackChunkName: "v-users-locations" */ '@/views/Users/Locations'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'user-locations'
              }
            }
          },
          { /* Users.Notes */
            name: 'Users.Notes',
            path: ':userid/notes',
            component: () => import(/* webpackChunkName: "v-users-notes" */ '@/views/Users/Notes'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'notes'
              }
            }
          },
          { /* Users.ChangeTracking */
            name: 'Users.ChangeTracking',
            path: ':userid/changetracking',
            component: () => import(/* webpackChunkName: "v-users-changetracking" */ '@/views/Users/ChangeTracking'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'history'
              }
            }
          }
        ]
      },
      { /* SellingPoints */
        name: 'SellingPoints',
        path: 'sellingpoints',
        component: () => import(/* webpackChunkName: "v-sellingpoints" */ '@/views/SellingPoints'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'shop'
          }
        },
        children: [
          { /* SellingPoints.Add */
            name: 'SellingPoints.Add',
            path: 'add',
            component: () => import(/* webpackChunkName: "v-sellingpoints-add" */ '@/views/SellingPoints/Add')
          },
          { /* SellingPoints.DefaultRedirect */
            path: ':sellingpointid',
            redirect: { name: 'SellingPoints.Detail' }
          },
          { /* SellingPoints.Detail */
            name: 'SellingPoints.Detail',
            path: ':sellingpointid/detail',
            component: () => import(/* webpackChunkName: "v-sellingpoints-detail" */ '@/views/SellingPoints/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'shop'
              }
            }
          },
          { /* SellingPoints.ChangeTracking */
            name: 'SellingPoints.ChangeTracking',
            path: ':sellingpointid/changetracking',
            component: () => import(/* webpackChunkName: "v-sellingpoints-changetracking" */ '@/views/SellingPoints/ChangeTracking'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'history'
              }
            }
          }
        ]
      },
      { /* Orders */
        name: 'Orders',
        path: 'orders',
        component: () => import(/* webpackChunkName: "v-orders" */ '@/views/Orders'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'cart'
          }
        },
        children: [
          { /* Orders.DefaultRedirect */
            path: ':orderid',
            redirect: { name: 'Orders.Detail' }
          },
          { /* Orders.Detail */
            name: 'Orders.Detail',
            path: ':orderid/detail',
            component: () => import(/* webpackChunkName: "v-orders-detail" */ '@/views/Orders/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'location'
              }
            }
          },
          { /* Orders.Details */
            name: 'Orders.Addressing',
            path: ':orderid/addressing',
            component: () => import(/* webpackChunkName: "v-orders-notes" */ '@/views/Orders/Addressing'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'location'
              }
            }
          },
          { /* Orders.Notes */
            name: 'Orders.Notes',
            path: ':orderid/notes',
            component: () => import(/* webpackChunkName: "v-orders-notes" */ '@/views/Orders/Notes'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'notes'
              }
            }
          },
          { /* Orders.ChangeTracking */
            name: 'Orders.ChangeTracking',
            path: ':orderid/changetracking',
            component: () => import(/* webpackChunkName: "v-orders-changetracking" */ '@/views/Orders/ChangeTracking'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'history'
              }
            }
          }
        ]
      },
      { /* Login */
        name: 'Login',
        path: 'auth/login',
        component: () => import(/* webpackChunkName: "v-auth-login" */ '@/views/auth/Login')
      },
      { /* Logout */
        name: 'Logout',
        path: 'auth/logout',
        component: () => import(/* webpackChunkName: "v-auth-logout" */ '@/views/auth/Logout')
      },
      { /* Forbidden (403) */
        name: 'Forbidden',
        path: 'auth/403',
        component: () => import(/* webpackChunkName: "v-auth-forbidden" */ '@/views/auth/Forbidden')
      },
      { /* Notfound (404) */
        name: 'Notfound',
        path: '404',
        component: () => import(/* webpackChunkName: "v-notfound" */ '@/views/Notfound')
      },
      {
        name: 'Refresh',
        path: 'refresh',
        component: {
          render: h => h('div'),
          beforeRouteEnter (to, from, next) {
            next(vm => {
              vm.$router.replace(from.path)
            })
          }
        }
      },
      {
        path: '*',
        redirect: { name: 'Notfound' }
      }
    ]
  }
]
