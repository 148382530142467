import { STOREMODULE_ENDPOINT_MAP } from '@/assets/js/config/server'

import merge from 'lodash.merge'
import string from '@/assets/js/helper/string'

function createState () {
  return merge({}, ...arguments)
}

function createGetters () {
  return merge({}, ...arguments)
}

function createMutations () {
  return merge({}, ...arguments)
}

function createActions () {
  return merge({}, ...arguments)
}

function createEntityActions (serverModuleName = '', serverEntityName = '', ...args) {
  const entityKey = Object.keys(STOREMODULE_ENDPOINT_MAP).find(mKey => STOREMODULE_ENDPOINT_MAP[mKey] === serverEntityName) || serverEntityName
  return merge({}, ...args.map(arg => Object.keys(arg).reduce((actions, aKey) => Object.assign(actions, { [aKey]: arg[aKey](serverModuleName, serverEntityName, entityKey) }), {})))
}

function getOptions ({ storeModule = '', by = {}, data = {} }, customData = {}) {
  if (by.name) by.name = string.singularize(by.name)

  const o = {
    storeModule: storeModule,
    by: Object.assign({ name: null, id: null }, by),
    data: Object.assign(data, customData)
  }

  return {
    storeModule: o.storeModule,
    by: Object.assign(o.by, {
      url: {
        entity: o.by.name ? `/${o.by.name}/${o.by.id}` : '',
        from: o.by.name && o.by.id ? `/from${o.by.name}/${o.by.id}` : ''
      }
    }),
    data: o.data
  }
}

export default {
  createState,
  createGetters,
  createMutations,
  createActions,
  createEntityActions,
  getOptions
}
